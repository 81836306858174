import React, { useState, useContext } from 'react';
import './header.css';
import { AppContext } from '../AppContext';


function Header({ toggleActive }) {
  const { credits, defaultUserImg, userImg, userName } = useContext(AppContext);
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!menu);
    toggleActive();
    window.dispatchEvent(new CustomEvent('factoryResize'));
  };

  const userProfileImage = userImg || defaultUserImg;

  return (
    <header>
      <div className="d-flex align-items-center">
        <a
          href="#"
          className={`menu ${menu ? 'active' : undefined}`}
          onClick={toggleMenu}
        >
          <i class="bi bi-menu-app"></i>
        </a>
      </div>

      <div className="userItems">
        
        <span className="credText">CRED: </span>
        <span className="cred">{credits}</span>
        <div className="avatar">
          <img src={userProfileImage} referrerPolicy="no-referrer" alt="User" />
        </div>
        <div className="user">
          <span>{userName}</span>
        </div>
      </div>
    </header>
  );
}

export default Header;

import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
// Loading Assets (SubComponents & CSS)
import "../../../css/Popup.css";
import "../../../css/Welcome.css";
//import logo from '../assets/New_Logo.svg';
import popbg from '../../../assets/welcome_Images/main2.png';
//const message = this.props.message ? this.props.message : <a href="http://www.example.com">No message provided</a>;
//<p>{this.props.message}</p>


class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
        message: '',
        date: '',
        welcome: this.props.welcome
    };
}


componentDidMount() {
  console.log('Getting motd');
  this.getMotd();
}

getMotd = async () => {
  try {
    // Fetch the settings to get the current MOTD ID
    let response = await axios.get('https://czbackend.herokuapp.com/api/elements/settings');
    let settings = response.data;

    console.log('Received response from /api/elements/settings:', settings);

    // Assume the first row contains the setting we need
    let motdId = settings[0].motd;

    // Fetch the MOTD with the current ID
    response = await axios.get(`https://czbackend.herokuapp.com/api/elements/motd/${motdId}`);
    let motd = response.data;

    console.log('Received response from /api/elements/motd:', motd);

    // Extract only the date from created_at
    const date = new Date(motd.created_at).toLocaleDateString();

    // Update state with the message and date
    this.setState({ message: motd.message, date: date });
  } catch (error) {
    console.error('Failed to fetch MOTD:', error);
  }
};

    render() {
        if (this.props.welcome) {
            
            return (
                
                <div className="screenpop abs top left">
                  <div className="popup_welcome">
                    <div className="welcome-content">
                      <img className="pop-img_welcome" src={popbg} alt="" title="Placeholder image. Looks cool right? From nicepng.com." />
                      <div className="welcome-title">
                        <p>Welcome to Citizen Zero!</p>
                      </div>
                      <div className="spacerwel"></div>
                      <div className="date-text"> 
                    {this.state.date}
                    </div>
                      <div className="textback">

                    <div className="welcome-text"> 
                    <p>{this.state.message}</p>
                    </div>
                    </div>
                  <button
                    className="custom-btn btn-09"
                    onClick={() => {
                    window.location.href = "mailto:dude_its_Matt_G@proton.me";
                    }}
                  >
                  SEND ME AN EMAIL 💌
                  </button>


<button
    className="custom-btn btn-10"
    onClick={() => { 
        window.open("https://www.buymeacoffee.com/Dude.its.Matt.G", "_blank");
    }}
>
BUY ME A HOTDOG 🌭
</button>

<div className="space"></div>
              <button
                  className="custom-btn btn-11"
                  onClick={() => {
                    this.props.updateWelcome(false);
                  }}
                >
                  ENTER 🤘
                </button>
            </div>
          </div>
        </div>
      );
        } else {
            return (
                <div/>
            );
        }
    }
}

export default Welcome;
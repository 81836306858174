import React, {Component} from "react";
//<img src={logo} className="App-logo" alt="logo"/>
// Loading Assets (SubComponents & CSS)
//import logo from "../assets/Logo_Light.png";
import "../css/Loader.css";

class PartLoader extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.check = setInterval(() => {
            if (window.partloaded) {
                clearInterval(this.check);
                this.props.updateLoading(false)
            }
        }, 200);
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        clearInterval(this.check)
        return null
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="partscreen partwebloader top left">
                    <div className="partloaderimg">
                        


                    </div>
                </div>
            );
        } else {
            return (
                <div/>
            );
        }
    }
}

export default PartLoader
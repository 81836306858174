import React, {Component} from "react";
import { useState } from "react";
// Loading Assets (SubComponents & CSS)
import Selector from "../Selector.js";
import "../../css/Category.css";
import Name from '../Name.js';
import PoseToggle from "../Poser.js";

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLeft: true,
            avatarName: props.avatarName,
            isSidebarVisible: true,
            isRightSidebarVisible: true,
            currentCategory: props.currentCategory,
            updateCategory: props.updateCategory,
        };
        this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
        this.handleRightSidebarToggle = this.handleRightSidebarToggle.bind(this);
    }

    handleSidebarToggle() {
        this.setState({ isSidebarVisible: !this.state.isSidebarVisible });
      }

    handleRightSidebarToggle() {
    this.setState({ isRightSidebarVisible: !this.state.isRightSidebarVisible });
    }
    
    // Update the state of parent App from child Component
    updateLeft = isLeft => {
        this.setState({isLeft});
    };

    updateCategory(name) {
        this.props.updateCategory(name);
    }

    updateAvatarName = (newName) => {
        this.props.updateAvatarName(newName);
      };

    render() {
        // Passing through the state from the properties
        const category = this.props.category;
        const current = this.props.currentCategory;
        const onToggle = this.props.poseToggle;

        //JSX element to display the HTML
        const categoryDiv = [];

        for (let i = 0; i < category.length; i++) {
            let name = category[i].name;
            let file = category[i].img_file;
            let displayName = category[i].displayName;
            if (name === current) {
                categoryDiv.push(
                    <div className="category selected-category" key={i}>
                        {/* <img src={"https://citizenzero.s3.us-west-1.amazonaws.com/icons/" + file} alt={name}/> */}
                        <div className="unselectable selected_category-name">
                                {displayName}
                            </div> 
                    </div>
                );
            } else {
                categoryDiv.push(
                    <div
                        className="category"
                        key={i}
                        onClick={() => {
                            this.props.updateCategory(name);
                            let meshType = undefined;
                            switch (name) {
                                case "head":
                                    meshType = "Head";
                                    break;
                                case "chest":
                                    meshType = "Chest";
                                    break;
                                case "hand":
                                    meshType = this.state.isLeft
                                        ? "HandL"
                                        : "HandR";
                                    break;
                                case "pad":
                                    meshType = this.state.isLeft
                                        ? "PadL"
                                        : "PadR";
                                    break;
                                case "arm":
                                    meshType = this.state.isLeft
                                        ? "ArmL"
                                        : "ArmR";
                                    break;
                                case "body":
                                    meshType = "Body";
                                    break;
                                case "foot":
                                    meshType = this.state.isLeft
                                        ? "FootL"
                                        : "FootR";
                                    break;
                                case "leg":
                                    meshType = this.state.isLeft
                                        ? "LegL"
                                        : "LegR";
                                    break;
                                case "pose":
                                    meshType = "pose";
                                    break;
                                case "stand":
                                    meshType = "mesh-stand";
                                    break;
                                case "env":
                                    meshType = "meshEnv";
                                    break;
                                case "podium":
                                    meshType = "Podium";
                                    break;
                                case "prop01":
                                    meshType = "Prop01";
                                    break;
                                case "prop02":
                                    meshType = "Prop02";
                                    break;
                                case "prop03":
                                    meshType = "Prop04";
                                    break;
                                default:
                                    meshType = undefined;
                            }
                            
                            if (meshType) {
                                window.selectedMesh(meshType);
                            }
                        }}
                    >
                         {/* <img src={"https://citizenzero.s3.us-west-1.amazonaws.com/icons/" + file} alt={name}/> */}
                        <div className="unselectable category-name">
                                {displayName}
                            </div>
                    </div>
                );
            }
        }

        if (this.props.UIDisplayed) {
            return (
                
                <div className="all">
                



                <div className={`rightbar ${this.state.isRightSidebarVisible ? '' : 'hiddenrt'}`}>
                <Selector
                    currentCategory={this.props.currentCategory}
                    isLeft={this.state.isLeft}
                    updateLeft={this.updateLeft}
                    loadedMeshes={this.props.loadedMeshes}
                    updateMeshes={this.props.updateMeshes}
                    updatePopup={this.props.updatePopup}
                    updatePopupMessage={this.props.updatePopupMessage}
                    editor={this.props.editor}
                    updateLoading={this.props.updateLoading}
                    customPoseData={this.state.customPoseData}
                    resetLoadedMeshes={this.props.resetLoadedMeshes}
                    onToggle={this.handleToggleChange}
                    poseToggle={this.props.poseToggle}
                    />
               <button onClick={this.handleRightSidebarToggle} className={`clipglow ${this.state.isRightSidebarVisible ? 'sidebar-toggle-button-right' : 'sidebar-toggle-button-right-hidden'}`}>||</button>
                  
            </div>
            

                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default Category;
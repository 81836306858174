import React, { useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import './showcase.css';
// import required modules
import { EffectCoverflow, Navigation, Autoplay } from 'swiper/modules';

//idk keep for rainy day or something
//<div className='swiperBanner'>
//Check it out
//</div>
//<h2>{game.title}</h2>
//<p>{game.description}</p>

function Showcase({ games }) {
  const [active, setActive] = useState(false);
  const handleToggleVideo = () => {
    setActive(!active);
  };

  return (
    <>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        navigation={true}
        loop={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 35,
          stretch: 200,
          depth: 250,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[EffectCoverflow, Navigation, Autoplay]}
        className="Showcase"
      >
        {games.map(game => (
          <SwiperSlide key={game._id.toString()}>
            <div className="Showcase">
              <img src={game.img} />
              <div className={`video ${active ? 'active' : undefined}`}>
                <iframe
                  width="1280"
                  height="720"
                  src={game.trailer}
                  title={game.title}
                  //allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="content">

                <div className="showButtons">
                  <a className="orderBtn" href="#">
                    EQUIP NOW
                  </a>
                  
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default Showcase;

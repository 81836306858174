import React, { useState } from 'react';
import './categories.css';
import ItemCard from '../../components/itemCard/ItemCard';
import filterListData from '../../data/filterListData';

function Categories({ games, reference, updateCredits }) {
  const [data, setData] = useState(games);
  const [filters, setFilters] = useState(filterListData);

  const handleFilterGames = category => {
    setFilters(
      filters.map(filter => {
        filter.active = false;
        if (filter.category === category) {
          filter.active = true;
        }
        return filter;
      })
    );

    if (category === 'All') {
      setData(games);
      return;
    }
    const filteredGames = games.filter(game => game.category === category);
    setData(filteredGames);
  };

  const [text, setText] = useState('');

  const handleSearchGames = e => {
    setData(
      games.filter(game =>
        game.item_name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setText(e.target.value);
  };

  return (
    <section id="categories" className="categories" ref={reference}>
      <div className="container-fluid mt-2">
        
        {/* Search bar row */}
        <div className="row" data-aos="fade-up" data-aos-delay="300">
          <div className="col-12 d-flex align-items-center justify-content-start">
            <div className="search">
              <input
                type="text"
                name="search"
                value={text}
                placeholder="Search"
                onChange={handleSearchGames}
              />
            </div>
          </div>
        </div>
  
        {/* Filters row */}
        <div className="row" data-aos="fade-up" data-aos-delay="300">
          <div className="col-12 d-flex align-items-center justify-content-start">
            <ul className="filters">
              {filters.map(filter => (
                <li
                  key={filter.category}
                  className={`${filter.active ? 'active' : ''}`}
                  onClick={() => handleFilterGames(filter.name)}
                >
                  {filter.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
  
        {/* Cards row */}
        <div className="row cards">
          <div className="col-12">
            <div className="row">
              {data.map(allItem => (
                <div className="col-lg-3 col-md-6 col-sm-12 extendo">
                  <ItemCard key={allItem.item_id} saleItem={allItem} updateCredits={updateCredits} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
}

export default Categories;

//import { waitFor } from "@testing-library/react";
import React, { Component } from "react";
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import logo from '../assets/logo/CZSVGLOGO.svg';
import loading from "../assets/loading/loading.svg";
import "../css/PageLoader.css";
import "../css/Welcome.css";


class PageLoader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            modelLoaded: false,
        };
        this.scene = new THREE.Scene();
    }

    

    componentDidMount() {
        this.initThreeJS();
        this.setRandomLogoImage();
        this.check = setInterval(() => {
            if (window.loaded) {
                clearInterval(this.check);
                this.setState({ loading: false })
            }
        }, 7000)
    }

    setRandomLogoImage = () => {
        const logoImages = [
            "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Inferno+Beach/nz.jpg",
            "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/lighthouse/pz.jpg",
            "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Forgotten+Grove/nx.jpg",
            "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Simulation+P-9/px.jpg",
        ];
        const randomIndex = Math.floor(Math.random() * logoImages.length);
        const selectedLogo = logoImages[randomIndex];
        this.setState({ logoImageUrl: selectedLogo });
    };

    initThreeJS = () => {
        const container = this.mount;
        if (!container) return; // Ensure container exists

        // Calculate initial container dimensions
        const width = container.clientWidth;
        const height = container.clientHeight;

        const modelUrls = [
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Arm+Stretching.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Cheering.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Happy+Idle.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Listening+To+Music.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Looking+Around.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Offensive+Idle.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Spin+In+Place.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Talking+On+A+Cell+Phone.fbx',
            // Add more model URLs as needed
        ];

        const randomIndex = Math.floor(Math.random() * modelUrls.length);
        const selectedModelUrl = modelUrls[randomIndex];

        const camera = new THREE.PerspectiveCamera(50, 575 / 175, 1, 2000);
        camera.position.set(0, 0, 2);

        const scene = new THREE.Scene();
        //scene.background = new THREE.Color(0x1b1b1b);
        scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);

        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, .5);
        hemiLight.position.set(0, 200, 0);
        scene.add(hemiLight);

        const color = getComputedStyle(document.documentElement)
            .getPropertyValue('--secondary').trim() || '#ff5e00'; // Default color if CSS var is not found
        const colorHex = new THREE.Color(color);
        const dirLight2 = new THREE.DirectionalLight(colorHex, 4);
        dirLight2.position.set(5, 0.5, 0.2);
        scene.add(dirLight2);

        const dirLight = new THREE.DirectionalLight(0xffffff, .25);
        dirLight.position.set(0, 200, 100);
        dirLight.castShadow = true;
        dirLight.shadow.camera.top = 180;
        dirLight.shadow.camera.bottom = -100;
        dirLight.shadow.camera.left = -120;
        dirLight.shadow.camera.right = 120;
        scene.add(dirLight);

        //const mesh = new THREE.Mesh(new THREE.PlaneGeometry(2000, 2000), new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false }));
        // mesh.rotation.x = -Math.PI / 2;
        // mesh.receiveShadow = true;
        //scene.add(mesh);

        const grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
        grid.material.opacity = 0;
        grid.material.transparent = true;
        scene.add(grid);
        //grid "disabled"

        const loader = new FBXLoader();
        let mixer;
        loader.load(selectedModelUrl, (object) => {
            mixer = new THREE.AnimationMixer(object);
            const action = mixer.clipAction(object.animations[0]);
            action.play();

            const newMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff }); // Example: Red color

            object.traverse(function (child) {
                if (child.isMesh) {
                    child.material = newMaterial;
                    child.castShadow = true;
                    child.receiveShadow = true;
                }

            });
            object.position.set(0, -0.85, 0);
            scene.add(object);
        });

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, height); // Use container dimensions
        renderer.shadowMap.enabled = true;
        container.appendChild(renderer.domElement);

        //const controls = new OrbitControls(camera, renderer.domElement);
        //controls.target.set(0, 100, 0);
        //controls.update();

        //const stats = new Stats();
        //container.appendChild(stats.dom);

        const clock = new THREE.Clock();

        const animate = () => {
            requestAnimationFrame(animate);

            const delta = clock.getDelta();
            if (mixer) mixer.update(delta);

            renderer.render(scene, camera);
            //stats.update();
        };

        animate();

        window.addEventListener('resize', () => {
            // Recalculate container dimensions on resize
            const newWidth = container.clientWidth;
            const newHeight = container.clientHeight;

            // Update camera and renderer with new container dimensions
            camera.aspect = newWidth / newHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(newWidth, newHeight);
        });
    };



    render() {
        const { loading, logoImageUrl } = this.state; // Destructure state for easier access
        if (loading) {
            return (
                <div className="pageloadscreen abs top left">
                    <div className="loaderCentering">
                        <div ref={(mount) => { this.mount = mount }} className="three-canvas">
                        </div>
                        <div className="loadercontainer">
                            <img src={logoImageUrl} alt="Loading" style={{
                                maskImage: `url(${logo})`,
                                WebkitMaskImage: `url(${logo})`,
                                maskSize: 'cover',
                                WebkitMaskSize: 'cover',
                                maskRepeat: 'no-repeat',
                                WebkitMaskRepeat: 'no-repeat',
                                margin: 'auto',
                                width: '430px',
                                height: '190px',
                                position: 'relative',
                                top: '10px',
                                left: '5px',
                            }} />
                        </div>
                        <div className="loading-text-container">
                            <h1 className="loading-text neon">LOADING</h1>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div />
            );
        }
    }
}




export default PageLoader

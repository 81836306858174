const filterListData = [
  {
    _id: 1,
    name: 'All',
    active: true,
  },
  {
    _id: 2,
    name: 'head',
    active: false,
  },
  {
    _id: 3,
    name: 'pads',
    active: false,
  },
  {
    _id: 4,
    name: 'chest',
    active: false,
  },
  {
    _id: 5,
    name: 'arms',
    active: false,
  },
  {
    _id: 6,
    name: 'hands',
    active: false,
  },
  {
    _id: 7,
    name: 'waist',
    active: false,
  },
  {
    _id: 8,
    name: 'legs',
    active: false,
  },
  {
    _id: 9,
    name: 'feet',
    active: false,
  },
  {
    _id: 10,
    name: 'prop',
    active: false,
  },
];

export default filterListData;

const navListData = [
  {
    _id: 1,
    target: 'factory',
    name: `Factory`,
    icon: '🏭',
    active: false,
  },
  {
    _id: 2,
    target: 'home',
    name: `Home`,
    icon: '🏠',
    active: true,
  },
  {
    _id: 3,
    target: 'categories',
    name: `All Items`,
    icon: '🗂️',
    active: false,
  },
  {
    _id: 4,
    target: 'profile',
    name: `Profile`,
    icon: '😶',
    active: false,
  },
];

export default navListData;

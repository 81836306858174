//import { waitFor } from "@testing-library/react";
import React, { Component } from "react";
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import logo from '../../assets/logo/CZSVGLOGO.svg';
import "../../css/Welcome.css";
import "./mobilePage.css"


class MobilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            modelLoaded: false,
            logoImageUrl: '',
        };
        this.scene = new THREE.Scene();

    }



    componentDidMount() {
        this.initThreeJS();
        this.setRandomLogoImage();
    }

    setRandomLogoImage = () => {
        const logoImages = [
            "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Inferno+Beach/nz.jpg",
            "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/lighthouse/pz.jpg",
            "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Forgotten+Grove/nx.jpg",
            "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Simulation+P-9/px.jpg",
        ];
        const randomIndex = Math.floor(Math.random() * logoImages.length);
        const selectedLogo = logoImages[randomIndex];
        this.setState({ logoImageUrl: selectedLogo });
    };

    initThreeJS = () => {
        const container = this.mount;
        if (!container) return;

        const width = 300; // Fixed width
        const height = 500; // Fixed height

        const modelUrls = [
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Arm+Stretching.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Cheering.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Happy+Idle.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Listening+To+Music.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Looking+Around.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Offensive+Idle.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Spin+In+Place.fbx',
            'https://citizenzero.s3.us-west-1.amazonaws.com/models/pageloaders/Talking+On+A+Cell+Phone.fbx',
            // Add more model URLs as needed
        ];

        const randomIndex = Math.floor(Math.random() * modelUrls.length);
        const selectedModelUrl = modelUrls[randomIndex];

        const camera = new THREE.PerspectiveCamera(70, width / height, 0.1, 1000);
        camera.position.set(0, 0, 1.8); // Adjust as necessary to fit the model


        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0x1b1b1b);
        scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);

        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, .5);
        hemiLight.position.set(0, 200, 0);
        scene.add(hemiLight);

        const color = getComputedStyle(document.documentElement)
            .getPropertyValue('--secondary').trim() || '#ff5e00'; // Default color if CSS var is not found
        const colorHex = new THREE.Color(color);
        const dirLight2 = new THREE.DirectionalLight(colorHex, 1);
        dirLight2.position.set(2, 4, 0.2);
        scene.add(dirLight2);

        const dirLight = new THREE.DirectionalLight(0xffffff, .25);
        dirLight.position.set(0, 200, 100);
        dirLight.castShadow = true;
        dirLight.shadow.camera.top = 180;
        dirLight.shadow.camera.bottom = -100;
        dirLight.shadow.camera.left = -120;
        dirLight.shadow.camera.right = 120;
        scene.add(dirLight);

        //const mesh = new THREE.Mesh(new THREE.PlaneGeometry(2000, 2000), new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false }));
        // mesh.rotation.x = -Math.PI / 2;
        // mesh.receiveShadow = true;
        //scene.add(mesh);

        const grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
        grid.material.opacity = 0;
        grid.material.transparent = true;
        scene.add(grid);
        //grid "disabled"

        const loader = new FBXLoader();
        let mixer;
        loader.load(selectedModelUrl, (object) => {
            mixer = new THREE.AnimationMixer(object);
            const action = mixer.clipAction(object.animations[0]);
            action.play();

            const newMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff }); // Example: Red color

            object.traverse(function (child) {
                if (child.isMesh) {
                    child.material = newMaterial;
                    child.castShadow = true;
                    child.receiveShadow = true;
                    child.frustumCulled = false;
                }

            });
            object.position.set(0, -0.85, 0);
            scene.add(object);
        });

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, height); // Use container dimensions
        renderer.shadowMap.enabled = true;
        container.appendChild(renderer.domElement);

        //const controls = new OrbitControls(camera, renderer.domElement);
        //controls.target.set(0, 100, 0);
        //controls.update();

        //const stats = new Stats();
        //container.appendChild(stats.dom);

        const clock = new THREE.Clock();

        const animate = () => {
            requestAnimationFrame(animate);

            const delta = clock.getDelta();
            if (mixer) mixer.update(delta);

            renderer.render(scene, camera);
            //stats.update();
        };

        animate();

        window.addEventListener('resize', () => {
            // Recalculate container dimensions on resize
            const newWidth = container.clientWidth;
            const newHeight = container.clientHeight;

            // Update camera and renderer with new container dimensions
            camera.aspect = newWidth / newHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(newWidth, newHeight);
        });
    };



    render() {
        const { logoImageUrl } = this.state;
        return (
            <div className="mobileloadscreen">
                <div className="mobilecontainer">
                    <img src={logoImageUrl} alt="Loading" style={{
                        maskImage: `url(${logo})`,
                        WebkitMaskImage: `url(${logo})`,
                        maskSize: 'contain', // Ensures the mask fits without exceeding the image bounds
                        WebkitMaskSize: 'contain',
                        maskRepeat: 'no-repeat',
                        WebkitMaskRepeat: 'no-repeat',
                        width: '95vw', // Adjusts width to 95% of the viewport width
                        height: 'auto', // Scales height automatically to maintain aspect ratio
                        objectFit: 'cover', // Ensures the image itself covers the area without distortion
                        display: 'block', // Eliminates any extra space below the image
                        margin: 'auto', // Centers the image horizontally
                        position: 'relative',
                        top: '2%',
                    }} />

                </div>
                <div className="footer-text">
                    <h1 className="textError">Device Compatibility Notice</h1>
                    <p className="footerP">Mobile devices are not supported at this time.</p>
                    <h2 className="modalTitle">Support Citizen Zero</h2>
                    <p className="footerP">Tap below to support Citizen Zero!</p>
                    <br />
                    <button
                        className="custom-btn btn-10"
                        onClick={() => {
                            window.open("https://www.buymeacoffee.com/Dude.its.Matt.G", "_blank");
                        }}
                    >
                        BUY A HOTDOG 🌭
                    </button>
                </div>
            </div>







        );
    }
}




export default MobilePage

import React, { Component } from 'react';
import './factory.css';
import MainStage from '../../components/webgl/MainStage';
import PageLoader from "../../components/PageLoader";
import Name from "../../components/Name";
import Download from "../../components/Download";
import ColorSelector from "../../components/ColorSelector";
import Category from "../../components/category/Category";
import PartLoader from "../../components/PartLoader";
import Screenshot from "../../components/Screenshot";
import Welcome from '../../components/modal/welcome/Welcome';
import Editor from '../../Backups/Editor';
import Selector from '../../components/Selector';
import CategoryList from "../../library/category.json";

class Factory extends Component {
  constructor(props) {
    super(props);
    this.resetLoadedMeshes = this.resetLoadedMeshes.bind(this);
    // Initialize state
    this.state = {
      category: CategoryList,
      currentCategory: props.currentCategory,
      updateCategory: props.updateCategory,
      avatarName: "Cosmo",
      UIDisplayed: true,
      welcome: false,
      message: "MESSAGE",
      loadedMeshes: {
        Body: "LPCOSMO",
        Chest: "LPC_Chest",
        LegR: "no_leg_R",
        LegL: "no_leg_L",
        PadR: "no_pad_R",
        PadL: "no_pad_L",
        Head: "no_hat",
        ArmR: "no_arm_R",
        ArmL: "no_arm_L",
        HandR: "no_hand_R",
        HandL: "no_hand_L",
        FootR: "no_foot_R",
        FootL: "no_foot_L",
        Stand: "circle2",
        Env: "meshEnv",
        Podium: "none",
        Prop01: "none",
        Prop02: "none",
        Prop03: "none"
      },
      screenshot: true,
      editor: false,
      partLoading: false,
      animate: props.animate,
      poseToggle: props.poseToggle,
    }
    this.mainStage = null;
  };

  // Factory App Reset Loaded Meshes
  resetLoadedMeshes = () => {
    this.setState({
      loadedMeshes: {
        Head: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        PadR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        PadL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        Chest: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        LegR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        LegL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        ArmR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        ArmL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        HandR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        HandL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        FootR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        FootL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        }
      },
    });
  };


  componentDidMount() {
    this.mainStage = new MainStage();
    this.mainStage.init();
    this.mainStage.animate();

  }


  componentDidUpdate() {
    if (this.mainStage) {
      this.mainStage.updatePoseToggle(this.props.poseToggle);
    }
  }

  componentWillUnmount() {
    // Cleanup your component here if necessary (e.g., remove event listeners, cancel requests, etc.)
  }

  // Update the state of parent App from child Component

  updateAvatarName = avatarName => {
    this.setState({ avatarName });
  };
  updateWelcome = welcome => {
    this.setState({ welcome });
  };
  updatePopup = popup => {
    this.setState({ popup });
  };
  updateMeshes = loadedMeshes => {
    this.setState({ loadedMeshes });
  };
  updateLoading = partLoading => {
    this.setState({ partLoading });
  };
  updatePopupMessage = message => {
    this.setState({ message });
  };
  updateScreenshot = screenshot => {
    this.setState({ screenshot });
  };
  updateCategory = currentCategory => {
    this.setState({ currentCategory });
  };


  render() {
    return (
      <section id="factory" className="factory" ref={this.props.reference} style={{ height: '100vh', width: '100vw' }}>
        <div id="FactoryApp">

        <PageLoader/>
          
          <Download
            avatarName={this.state.avatarName}
            loadedMeshes={this.state.loadedMeshes}
          />
          
          <Welcome
            welcome={this.state.welcome}
            message={this.state.message}
            updateWelcome={this.updateWelcome}
          />
          <Category
            category={this.props.category}
            updateCategory={this.props.updateCategory}
            currentCategory={this.props.currentCategory}
            updatePose={this.updatePose}
            UIDisplayed={this.state.UIDisplayed}
            loadedMeshes={this.state.loadedMeshes}
            updateMeshes={this.updateMeshes}
            updatePopup={this.updatePopup}
            updatePopupMessage={this.updatePopupMessage}
            editor={this.state.editor}
            updateLoading={this.updateLoading}
            avatarName={this.state.avatarName}
            updateAvatarName={this.updateAvatarName}
            onPoseToggleChange={this.handlePoseToggleChange}
            resetLoadedMeshes={this.resetLoadedMeshes}
            animate={this.props.animate}
            poseToggle={this.props.poseToggle}
          />
          <PartLoader
            loading={this.state.partLoading}
            updateLoading={this.updateLoading}
          />
        </div>
      </section>
    );
  }
}

export default Factory;

import React, { useState, useEffect, useContext } from 'react';
import './bag.css';
import ShopBagItem from '../../components/ShopBagItem';
import { AppContext } from '../../App'; // Import AppContext

function Bag({ games, reference }) {
  const { setTotal } = useContext(AppContext); // Access updateTotal from AppContext
  const [total] = useState(0);

  const handleTotalPayment = () => {
    let total = games
      .map(game => game.price * (1 - game.discount))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      .toFixed(0); //decimal places

    return total;
  };

  useEffect(() => {
    const newTotal = handleTotalPayment();
    setTotal(newTotal);

    // Update the total in the App component
    //setTotal(newTotal);
  }, [games, setTotal]);

  return (
    <section id="bag" className="bag" ref={reference}>
      <div className="container-fluid">
        <div className="row mb-3">
          <h1>My Stash</h1>
        </div>
        {games.length === 0 ? (
          <i><h2>Proof of existence is required. Please log in.</h2></i>
        ) : (
          <>
            <div className="row">
              <div class="table-responsive">
                <table className="shopBagTable table table-borderless align-middle ">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Preview</th>
                      <th scope="col">Game</th>
                      <th scope="col">Price</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Payment</th>
                      <th scope="col">Remove</th>
                    </tr>
                  </thead>

                  <tbody>
                    {games.map((game, index) => (
                      <ShopBagItem index={index} key={game._id} game={game} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row d-flex justify-content-between mt-5">
              <div className="col-lg-2 align-items-center">
                <p className="itemCount">Total Items: {games.length}</p>
              </div>
              <div className="col-lg-10 d-flex justify-content-end">
                <div className="payment">
                  Total: ${total}
                  <a href="#">
                    Check out <i class="bi bi-wallet-fill"></i>
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Bag;

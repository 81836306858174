import React, {Component} from "react";
import "../css/Name.css";

class Name extends Component {
  handleChange = (event) => {
    this.props.updateAvatarName(event.target.value);
    window.avatarName = event.target.value;
  };

  render() {
    window.avatarName = this.props.avatarName;
    // Passing thought the name state from the properties
    return (
      <input
        type="text"
        className="name nameWidth"
        value={this.props.avatarName}
        onChange={this.handleChange}
        maxLength={15} // Set the character limit here, e.g., 50 characters
      />
    );
  }
}

export default Name;

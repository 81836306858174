import React, { useState, useContext, useEffect } from "react";
import '../css/ColorSelector.css';
import { AppContext } from '../pages/AppContext';
import MainStage from '../components/webgl/MainStage';

const ColorSelector = () => {
  const { color, setColor } = useContext(AppContext);
  
  // Define an array of preset colors
  const colors = ["#c40000","#e41ca1", "#B8FF00", "#0066a1", "#7900ca", "#d3d300","#ff9100", "#4e4e4e","#9c9c9c", "#FFFFFF"];
  
  // State to hold the input hex color
  const [customColor, setCustomColor] = useState("");
  
  // State to manage the placeholder
  const [placeholder, setPlaceholder] = useState("#B8FF00");

  // Handle color change
  const handleColorChange = (color) => {
    setColor(color);
    console.log("COLOR SET TO: " + color);

    // Update the placeholder with the selected color
    setPlaceholder(color);

    // Use the globally available setMeshColor function
    if (typeof window.setMeshColor === "function") {
      window.setMeshColor(color);
    }
  };

  // Handle custom color submit
  const handleCustomColorSubmit = () => {
    if (/^#[0-9A-F]{6}$/i.test(customColor)) {
      handleColorChange(customColor);
    } else {
      console.error("Invalid hex color format.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCustomColorSubmit(); // Call the submit function when Enter is pressed
    }
};

  return (
    <div className="color-selector">
      <div className="custom-color-input">
        <div>Armor Finish: </div>
      <input
            className="color-input-width"
            maxLength="7"
            type="text"
            value={customColor}
            onChange={(e) => setCustomColor(e.target.value)}
            onKeyPress={handleKeyPress}  // Listen for keypress events
            placeholder={placeholder}  // Use the dynamic placeholder
        />
        
      </div>
      <div className="color-presets">
      {colors.map((color, index) => (
        <button
          key={index}
          className="color-button"
          style={{ backgroundColor: color }}
          onClick={() => handleColorChange(color)}
        />
      ))}
      </div>
    </div>
  );
};

export default ColorSelector;

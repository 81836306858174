import React, { useEffect, Component } from 'react';
import './factoryMenu.css';
import logosvg from '../../assets/logo/CZSVGLOGO.svg';
import Name from '../Name';
import PoseToggle from "../Poser";
import Selector from "../Selector";
import NavListItem from '../NavListItem';

class FactoryMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLeft: true,
      avatarName: props.avatarName,
      isSidebarVisible: true,
      isRightSidebarVisible: true,
      category: props.category,
      animate: props.animate,
      currentCategory: props.currentCategory || "pose",
      updateCategory: props.updateCategory,

    };
  }

  componentDidMount() {
    // Code here runs once when the component mounts
    this.initializeCategory();
  }

  componentDidUpdate(prevProps) {
    // Similar to useEffect with dependency array
    if (prevProps.currentCategory !== this.props.currentCategory) {
      this.updateCategoryStates();
    }
  }

  
  initializeCategory() {
    // Determine the initial category - default to "pose" if no currentCategory is provided
    const initialCategory = this.props.currentCategory || "pose";
  
    // Map through the categories and set 'active' to true for the initial category
    const updatedCategory = this.state.category.map(cat => ({
      ...cat,
      active: cat.name === initialCategory
    }));
  
    // Update the state with the modified categories
    this.setState({
      category: updatedCategory,
      currentCategory: initialCategory  // Also set the currentCategory state
    });
  
    // Optionally update the parent component or other consumers if needed
    this.props.updateCategory(initialCategory);
  }
  

  updateCategoryStates() {
    const updatedCategory = this.state.category.map(cat => ({
      ...cat,
      active: cat.name === this.props.currentCategory
    }));
    this.setState({ category: updatedCategory });
  }

  updateCategory = currentCategory => {
    this.setState({ currentCategory });
    this.props.updateCategory(currentCategory);
  };

  updateLeft = (isLeft) => {
    this.setState({ isLeft });
  };

  updateAvatarName = (newName) => {
    this.setState({ avatarName: newName });
  };

  handleToggleChange = (isToggled) => {
    this.props.onPoseToggleChange(isToggled); // This should call the function passed from Main.jsx
  };

  updateCategory = (currentCategory) => {
    console.log("Updating category to:", currentCategory); // Debug log
    this.setState({ currentCategory });
    this.props.updateCategory(currentCategory);
  };


  render() {
    const { category, currentCategory, UIDisplayed } = this.props;
    const { avatarName } = this.state;
    const { animate } = this.state;
    const { poseToggle } = this.state;
    const categoryDiv = category.map((item, i) => {
      let meshType;
      let iconEmoji;
      switch (item.name) {
        case "head":
          meshType = "Head";
          iconEmoji = "🎩";
          break;
        case "chest":
          meshType = "Chest";
          iconEmoji = "🦺";
          break;
        case "hand":
          meshType = this.state.isLeft
            ? "HandL"
            : "HandR";
          iconEmoji = "👋";
          break;
        case "pad":
          meshType = this.state.isLeft
            ? "PadL"
            : "PadR";
          iconEmoji = "🛡️";
          break;
        case "arm":
          meshType = this.state.isLeft
            ? "ArmL"
            : "ArmR";
          iconEmoji = "💪";
          break;
        case "body":
          meshType = "Body";
          iconEmoji = "🚻";
          break;
        case "foot":
          meshType = this.state.isLeft
            ? "FootL"
            : "FootR";
          iconEmoji = "👟";
          break;
        case "leg":
          meshType = this.state.isLeft
            ? "LegL"
            : "LegR";
          iconEmoji = "🦵";
          break;
        case "pose":
          meshType = "pose";
          iconEmoji = "🤸";
          break;
        case "stand":
          meshType = "mesh-stand";
          iconEmoji = "🧍";
          break;
        case "env":
          meshType = "meshEnv";
          iconEmoji = "🌍";
          break;
        case "podium":
          meshType = "Podium";
          iconEmoji = "🏆";
          break;
        case "skybox":
          iconEmoji = "🌇";
          break;
        case "prop01":
          meshType = "Prop01";
          iconEmoji = "🌭";
          break;
        case "prop02":
          meshType = "Prop02";
          iconEmoji = "🌭";
          break;
        case "prop03":
          meshType = "Prop03";
          iconEmoji = "🌭";
          break;
        default:
          meshType = undefined;
          iconEmoji = "❓";
      }

      if (meshType) {
        window.selectedMesh(meshType);
      }

      const isSelected = item.name === this.state.currentCategory;
      const categoryClass = isSelected ? 'active' : ''; // Update this line

      const onClick = () => {
        this.props.updateCategory(item.name);
        if (item.meshType) {
          window.selectedMesh(item.meshType);
        }
      };

      return (
        <li key={i} onClick={() => this.updateCategory(item.name)} className={isSelected ? 'active' : ''}>
          <a>
            <span className="factnavIcon">{iconEmoji}</span>
            <span className={`navName ${this.props.active ? 'hidden' : ''}`}>
              {item.displayName}
            </span>
          </a>
        </li>

      );
    });

    return (

      <div className={`factoryMenu ${this.props.active ? 'active' : ''}`}>
        <div className="logo">
          <img src={logosvg} alt="Logo Alt Text" />
        </div>
        <div className='factoryScroll invisascroll'>
          <ul className='nav'>
            <li onClick={this.props.onShopClick}>
              <a className='factoryButton'>
                <span className="factnavIcon">🏪</span>
                <span className={`navName ${this.props.active ? 'hidden' : ''}`}>
                  store
                </span>
              </a>
            </li>
          </ul>
          <div className={`nametitle ${this.props.active ? 'active' : ''}`}>CITIZEN NAME</div>
          <div className="nameleft">
            <Name
              avatarName={avatarName}
              updateAvatarName={this.updateAvatarName}
            />
            <PoseToggle
              onToggle={this.handleToggleChange}
              avatarName={avatarName}
            />
          </div>
          <ul className='nav'>
            {categoryDiv}
          </ul>

        </div>


      </div>
    );
  }
}

export default FactoryMenu;

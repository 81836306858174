import React, {Component} from "react";
// Loading Assets (SubComponents & CSS)
import "../css/Editor.css";
import Slider from '@mui/material/Slider';
import Switch, { SwitchProps } from '@mui/material/Switch';
import bones from "../library/bones.json";
import model from "../library/poses/model.json";
import trans from "../library/poses/translate.json";
import { TransformControls } from "../components/webgl/TransformControls";
//import NumericInput from "react-numeric-input";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../css/react-tabs.css';

/*eslint no-useless-computed-key: 0*/
class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Head_Main: {x: 0, y: 0, z: 0},
            Neck_Main: {x: 0, y: 0, z: 0},
            SpineHigh: {x: 0, y: 0, z: 0},
            SpineMid: {x: 0, y: 0, z: 0},
            SpineLow: {x: 0, y: 0, z: 0},
            Hips_Main: {x: 0, y: 0, z: 0},
            Root_Main: {x: 0, y: 0, z: 0},
            RightShoulder: {x: 0, y: 0, z: 0},
            RightArm: {x: 0, y: 0, z: 0},
            RightForeArm: {x: 0, y: 0, z: 0},
            RightHand: {x: 0, y: 0, z: 0},
            RightHandThumbLow: {x: 0, y: 0, z: 0},
            RightHandThumbMid: {x: 0, y: 0, z: 0},
            RightHandThumbHigh: {x: 0, y: 0, z: 0},
            RightHandIndexLow: {x: 0, y: 0, z: 0},
            RightHandIndexMid: {x: 0, y: 0, z: 0},
            RightHandIndexHigh: {x: 0, y: 0, z: 0},
            RightHandMiddleLow: {x: 0, y: 0, z: 0},
            RightHandMiddleMid: {x: 0, y: 0, z: 0},
            RightHandMiddleHigh: {x: 0, y: 0, z: 0},
            RightHandRingLow: {x: 0, y: 0, z: 0},
            RightHandRingMid: {x: 0, y: 0, z: 0},
            RightHandRingHigh: {x: 0, y: 0, z: 0},
            RightHandPinkyLow: {x: 0, y: 0, z: 0},
            RightHandPinkyMid: {x: 0, y: 0, z: 0},
            RightHandPinkyHigh: {x: 0, y: 0, z: 0},
            LeftShoulder: {x: 0, y: 0, z: 0},
            LeftArm: {x: 0, y: 0, z: 0},
            LeftForeArm: {x: 0, y: 0, z: 0},
            LeftHand: {x: 0, y: 0, z: 0},
            LeftHandThumbLow: {x: 0, y: 0, z: 0},
            LeftHandThumbMid: {x: 0, y: 0, z: 0},
            LeftHandThumbHigh: {x: 0, y: 0, z: 0},
            LeftHandIndexLow: {x: 0, y: 0, z: 0},
            LeftHandIndexMid: {x: 0, y: 0, z: 0},
            LeftHandIndexHigh: {x: 0, y: 0, z: 0},
            LeftHandMiddleLow: {x: 0, y: 0, z: 0},
            LeftHandMiddleMid: {x: 0, y: 0, z: 0},
            LeftHandMiddleHigh: {x: 0, y: 0, z: 0},
            LeftHandRingLow: {x: 0, y: 0, z: 0},
            LeftHandRingMid: {x: 0, y: 0, z: 0},
            LeftHandRingHigh: {x: 0, y: 0, z: 0},
            LeftHandPinkyLow: {x: 0, y: 0, z: 0},
            LeftHandPinkyMid: {x: 0, y: 0, z: 0},
            LeftHandPinkyHigh: {x: 0, y: 0, z: 0},
            RightUpLeg: {x: 0, y: 0, z: 0},
            RightLeg: {x: 0, y: 0, z: 0},
            RightFoot: {x: 0, y: 0, z: 0},
            RightToeBase: {x: 0, y: 0, z: 0},
            LeftUpLeg: {x: 0, y: 0, z: 0},
            LeftLeg: {x: 0, y: 0, z: 0},
            LeftFoot: {x: 0, y: 0, z: 0},
            LeftToeBase: {x: 0, y: 0, z: 0},
            trans: trans,
        };
        this.exportPose = this.exportPose.bind(this);
    }
    

    componentDidMount() {
        //const trans = require('../library/poses/translate.json');
        
        for (let i = 0; i < bones.length; i++) {
            let bone = bones[i].bone;
            this.setState({[bone]: window.getRotation(bone)})
        }
        
    }


    exportPose() {
        for (let i = 0; i < bones.length; i++) {
            let bone = bones[i].bone;
            model[bone] = this.state[bone];
        }
        let model_json_str = JSON.stringify(model);
        let element = document.createElement("a");
        let file = new Blob([model_json_str], {type: "application/json"});
        element.href = URL.createObjectURL(file);
        element.download = "newPose.json";
        element.click();
    }
    
    render() {
        return (
<div className="controls">

<Tabs defaultIndex={2}>
    <TabList>
      <Tab>Right Hand</Tab>
      <Tab>Right Arm</Tab>
      <Tab>Head</Tab>
      <Tab>Left Arm</Tab>
      <Tab>Left Hand</Tab>
    </TabList>

<TabPanel>
    <h2 class="headers">Right Hand</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["RightHand"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightHand"]: {x: value, y: this.state["RightHand"].y, z: this.state["RightHand"].z}});
            window.changeRotation("RightHand", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightHand"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["RightHand"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["RightHand"]: {x: this.state["RightHand"].x, y: value, z: this.state["RightHand"].z}
            });
            window.changeRotation("RightHand", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightHand"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["RightHand"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightHand"]: {x: this.state["RightHand"].x, y: this.state["RightHand"].y, z: value}});
            window.changeRotation("RightHand", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightHand"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
</TabPanel>


<TabPanel>
<h2 class="headers">Right Shoulder</h2>
      <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-3}
            max={1.3}
            value={Number(this.state["RightShoulder"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["RightShoulder"]: {x: value, y: this.state["RightShoulder"].y, z: this.state["RightShoulder"].z}
            });
            window.changeRotation("RightShoulder", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightShoulder"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={2.75}
            max={3}
            value={Number(this.state["RightShoulder"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["RightShoulder"]: {x: this.state["RightShoulder"].x, y: value, z: this.state["RightShoulder"].z}
            });
             window.changeRotation("RightShoulder", value, "y");
             // Apply the rotation to the child bones
            const children = this.state.trans["RightShoulder"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1.8}
            max={-1}
            value={Number(this.state["RightShoulder"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightShoulder"]: {x: this.state["RightShoulder"].x, y: this.state["RightShoulder"].y, z: value}});
            window.changeRotation("RightShoulder", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightShoulder"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
      <h2 class="headers">Right Arm</h2>
      <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1.2}
            max={1.5}
            value={Number(this.state["RightArm"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["RightArm"]: {x: value, y: this.state["RightArm"].y, z: this.state["RightArm"].z}
            });
            window.changeRotation("RightArm", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightArm"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["RightArm"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["RightArm"]: {x: this.state["RightArm"].x, y: value, z: this.state["RightArm"].z}
            });
             window.changeRotation("RightArm", value, "y");
             // Apply the rotation to the child bones
            const children = this.state.trans["RightArm"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1.6}
            max={1}
            value={Number(this.state["RightArm"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightArm"]: {x: this.state["RightArm"].x, y: this.state["RightArm"].y, z: value}});
            window.changeRotation("RightArm", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightArm"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    <h2 class="headers">Right Forearm</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-2}
            max={0.06}
            value={Number(this.state["RightForeArm"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightForeArm"]: {x: this.state["RightForeArm"].x, y: this.state["RightForeArm"].y, z: value}});
            window.changeRotation("RightForeArm", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightForeArm"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
</TabPanel>


<TabPanel>
{this.state.transformEnabled && this.renderTransformControls()}
    <h2 class="headers">Head</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={0.5}
            value={Number(this.state["Head_Main"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["Head_Main"]: {x: value, y: this.state["Head_Main"].y, z: this.state["Head_Main"].z}});
            window.changeRotation("Head_Main", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["Head_Main"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1}
            value={Number(this.state["Head_Main"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["Head_Main"]: {x: this.state["Head_Main"].x, y: value, z: this.state["Head_Main"].z}});
            window.changeRotation("Head_Main", value, "y");
             // Apply the rotation to the child bones
             const children = this.state.trans["Head_Main"];
             for (let child in children) {
                 window.changeRotation(child, value, "y");
     }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1}
            value={Number(this.state["Head_Main"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["Head_Main"]: {x: this.state["Head_Main"].x, y: this.state["Head_Main"].y, z: value}});
            window.changeRotation("Head_Main", value, "z");
             // Apply the rotation to the child bones
             const children = this.state.trans["Head_Main"];
             for (let child in children) {
                 window.changeRotation(child, value, "z");
     }
        }}/>
    </div>
    <h2 class="headers">Neck</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-0.25}
            max={0.5}
            value={Number(this.state["Neck_Main"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["Neck_Main"]: {x: value, y: this.state["Neck_Main"].y, z: this.state["Neck_Main"].z}});
            window.changeRotation("Neck_Main", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["Neck_Main"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1}
            value={Number(this.state["Neck_Main"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["Neck_Main"]: {x: this.state["Neck_Main"].x, y: value, z: this.state["Neck_Main"].z}});
            window.changeRotation("Neck_Main", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["Neck_Main"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-0.25}
            max={0.25}
            value={Number(this.state["Neck_Main"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["Neck_Main"]: {x: this.state["Neck_Main"].x, y: this.state["Neck_Main"].y, z: value}});
            window.changeRotation("Neck_Main", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["Neck_Main"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
</TabPanel>


<TabPanel>
<h2 class="headers">Left Arm</h2>
      <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1.2}
            max={1.5}
            value={Number(this.state["LeftArm"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["LeftArm"]: {x: value, y: this.state["LeftArm"].y, z: this.state["LeftArm"].z}
            });
            window.changeRotation("LeftArm", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftArm"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["LeftArm"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["LeftArm"]: {x: this.state["LeftArm"].x, y: value, z: this.state["LeftArm"].z}
            });
             window.changeRotation("LeftArm", value, "y");
             // Apply the rotation to the child bones
            const children = this.state.trans["LeftArm"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1.6}
            max={1}
            value={Number(this.state["LeftArm"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftArm"]: {x: this.state["LeftArm"].x, y: this.state["LeftArm"].y, z: value}});
            window.changeRotation("LeftArm", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftArm"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    <h2 class="headers">Left Shoulder</h2>
      <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-6}
            max={6}
            value={Number(this.state["LeftShoulder"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["LeftShoulder"]: {x: value, y: this.state["LeftShoulder"].y, z: this.state["LeftShoulder"].z}
            });
            window.changeRotation("LeftShoulder", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftShoulder"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-6}
            max={10}
            value={Number(this.state["LeftShoulder"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["LeftShoulder"]: {x: this.state["LeftShoulder"].x, y: value, z: this.state["LeftShoulder"].z}
            });
             window.changeRotation("LeftShoulder", value, "y");
             // Apply the rotation to the child bones
            const children = this.state.trans["LeftShoulder"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-6}
            max={6}
            value={Number(this.state["LeftShoulder"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftShoulder"]: {x: this.state["LeftShoulder"].x, y: this.state["LeftShoulder"].y, z: value}});
            window.changeRotation("LeftShoulder", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftShoulder"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    <h2 class="headers">Left Forearm</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-2}
            max={0.06}
            value={Number(this.state["LeftForeArm"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftForeArm"]: {x: this.state["LeftForeArm"].x, y: this.state["LeftForeArm"].y, z: value}});
            window.changeRotation("LeftForeArm", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftForeArm"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    </TabPanel>
    <TabPanel>
        <h2 class="headers">Left Hand</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["LeftHand"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftHand"]: {x: value, y: this.state["LeftHand"].y, z: this.state["LeftHand"].z}});
            window.changeRotation("LeftHand", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftHand"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["LeftHand"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["LeftHand"]: {x: this.state["LeftHand"].x, y: value, z: this.state["LeftHand"].z}});
            window.changeRotation("LeftHand", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftHand"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["LeftHand"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftHand"]: {x: this.state["LeftHand"].x, y: this.state["LeftHand"].y, z: value}});
            window.changeRotation("LeftHand", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftHand"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
</TabPanel>
</Tabs>
<hr></hr>
<hr></hr>
<Tabs defaultIndex={2}>
<TabList>
    <Tab>Right Fingers</Tab>
    <Tab>Right Leg</Tab>
    <Tab>Torso</Tab>
    <Tab>Left Leg</Tab>
    <Tab>Left Fingers</Tab>
</TabList>
<TabPanel>
<h2 class="headers">Thumb Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandThumbHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandThumbHigh"]: {x: value, y: this.state["RightHandThumbHigh"].y, z: this.state["RightHandThumbHigh"].z}});
        window.changeRotation("RightHandThumbHigh", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandThumbHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Thumb Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandThumbMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandThumbMid"]: {x: value, y: this.state["RightHandThumbMid"].y, z: this.state["RightHandThumbMid"].z}});
        window.changeRotation("RightHandThumbMid", value, "x");
        const children = this.state.trans["RightHandThumbMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Thumb Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandThumbLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandThumbLow"]: {x: value, y: this.state["RightHandThumbLow"].y, z: this.state["RightHandThumbLow"].z}});
        window.changeRotation("RightHandThumbLow", value, "x");
        const children = this.state.trans["RightHandThumbLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandThumbLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandThumbLow"]: {x: this.state["RightHandThumbLow"].x, y: this.state["RightHandThumbLow"].y, z: value}});
        window.changeRotation("RightHandThumbLow", value, "z");
        const children = this.state.trans["RightHandThumbLow"];
        for (let child in children) {
            window.changeRotation(child, value, "z");
        }
    }}/>
</div>
<h2 class="headers">Index Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandIndexHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandIndexHigh"]: {x: value, y: this.state["RightHandIndexHigh"].y, z: this.state["RightHandIndexHigh"].z}});
        window.changeRotation("RightHandIndexHigh", value, "x");
        const children = this.state.trans["RightHandIndexHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Index Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandIndexMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandIndexMid"]: {x: value, y: this.state["RightHandIndexMid"].y, z: this.state["RightHandIndexMid"].z}});
        window.changeRotation("RightHandIndexMid", value, "x");
        const children = this.state.trans["RightHandIndexMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Index Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandIndexLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandIndexLow"]: {x: value, y: this.state["RightHandIndexLow"].y, z: this.state["RightHandIndexLow"].z}});
        window.changeRotation("RightHandIndexLow", value, "x");
        const children = this.state.trans["RightHandIndexLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.001}
        min={-1}
        max={1.5}
        value={Number(this.state["RightHandIndexLow"].y).toFixed(2)}
        onChange={(event, value) => {
        this.setState({
            ["RightHandIndexLow"]: {x: this.state["RightHandIndexLow"].x, y: value, z: this.state["RightHandIndexLow"].z}
        });
        window.changeRotation("RightHandIndexLow", value, "y");
        const children = this.state.trans["RightHandIndexLow"];
        for (let child in children) {
            window.changeRotation(child, value, "y");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandIndexLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandIndexLow"]: {x: this.state["RightHandIndexLow"].x, y: this.state["RightHandIndexLow"].y, z: value}});
        window.changeRotation("RightHandIndexLow", value, "z");
        const children = this.state.trans["RightHandIndexLow"];
        for (let child in children) {
            window.changeRotation(child, value, "z");
        }
    }}/>
</div>
<h2 class="headers">Middle Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandMiddleHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandMiddleHigh"]: {x: value, y: this.state["RightHandMiddleHigh"].y, z: this.state["RightHandMiddleHigh"].z}});
        window.changeRotation("RightHandMiddleHigh", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandMiddleHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Middle Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandMiddleMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandMiddleMid"]: {x: value, y: this.state["RightHandMiddleMid"].y, z: this.state["RightHandMiddleMid"].z}});
        window.changeRotation("RightHandMiddleMid", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandMiddleMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Middle Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandMiddleLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandMiddleLow"]: {x: value, y: this.state["RightHandMiddleLow"].y, z: this.state["RightHandMiddleLow"].z}});
        window.changeRotation("RightHandMiddleLow", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandMiddleLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.001}
        min={-1}
        max={1.5}
        value={Number(this.state["RightHandMiddleLow"].y).toFixed(2)}
        onChange={(event, value) => {
        this.setState({
            ["RightHandMiddleLow"]: {x: this.state["RightHandMiddleLow"].x, y: value, z: this.state["RightHandMiddleLow"].z}
        });
        window.changeRotation("RightHandMiddleLow", value, "y");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandMiddleLow"];
        for (let child in children) {
            window.changeRotation(child, value, "y");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandMiddleLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandMiddleLow"]: {x: this.state["RightHandMiddleLow"].x, y: this.state["RightHandMiddleLow"].y, z: value}});
        window.changeRotation("RightHandMiddleLow", value, "z");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandMiddleLow"];
        for (let child in children) {
            window.changeRotation(child, value, "z");
        }
    }}/>
</div>
<h2 class="headers">Ring Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandRingHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandRingHigh"]: {x: value, y: this.state["RightHandRingHigh"].y, z: this.state["RightHandRingHigh"].z}});
        window.changeRotation("RightHandRingHigh", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandRingHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Ring Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandRingMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandRingMid"]: {x: value, y: this.state["RightHandRingMid"].y, z: this.state["RightHandRingMid"].z}});
        window.changeRotation("RightHandRingMid", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandRingMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Ring Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandRingLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandRingLow"]: {x: value, y: this.state["RightHandRingLow"].y, z: this.state["RightHandRingLow"].z}});
        window.changeRotation("RightHandRingLow", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandRingLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.001}
        min={-1}
        max={1.5}
        value={Number(this.state["RightHandRingLow"].y).toFixed(2)}
        onChange={(event, value) => {
        this.setState({
            ["RightHandRingLow"]: {x: this.state["RightHandRingLow"].x, y: value, z: this.state["RightHandRingLow"].z}
        });
        window.changeRotation("RightHandRingLow", value, "y");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandRingLow"];
        for (let child in children) {
            window.changeRotation(child, value, "y");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandRingLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandRingLow"]: {x: this.state["RightHandRingLow"].x, y: this.state["RightHandRingLow"].y, z: value}});
        window.changeRotation("RightHandRingLow", value, "z");
       // Apply the rotation to the child bones
       const children = this.state.trans["RightHandRingLow"];
       for (let child in children) {
           window.changeRotation(child, value, "z");
       }
    }}/>
</div>
<h2 class="headers">Pinky Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandPinkyHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandPinkyHigh"]: {x: value, y: this.state["RightHandPinkyHigh"].y, z: this.state["RightHandPinkyHigh"].z}});
        window.changeRotation("RightHandPinkyHigh", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandPinkyHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Pinky Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandPinkyMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandPinkyMid"]: {x: value, y: this.state["RightHandPinkyMid"].y, z: this.state["RightHandPinkyMid"].z}});
        window.changeRotation("RightHandPinkyMid", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandPinkyMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Pinky Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandPinkyLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandPinkyLow"]: {x: value, y: this.state["RightHandPinkyLow"].y, z: this.state["RightHandPinkyLow"].z}});
        window.changeRotation("RightHandPinkyLow", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandPinkyLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.001}
        min={-1}
        max={1.5}
        value={Number(this.state["RightHandPinkyLow"].y).toFixed(2)}
        onChange={(event, value) => {
        this.setState({
            ["RightHandPinkyLow"]: {x: this.state["RightHandPinkyLow"].x, y: value, z: this.state["RightHandPinkyLow"].z}
        });
        window.changeRotation("RightHandPinkyLow", value, "y");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandPinkyLow"];
        for (let child in children) {
            window.changeRotation(child, value, "y");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["RightHandPinkyLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["RightHandPinkyLow"]: {x: this.state["RightHandPinkyLow"].x, y: this.state["RightHandPinkyLow"].y, z: value}});
        window.changeRotation("RightHandPinkyLow", value, "z");
        // Apply the rotation to the child bones
        const children = this.state.trans["RightHandPinkyLow"];
        for (let child in children) {
            window.changeRotation(child, value, "z");
        }
    }}/>
</div>
</TabPanel>
<TabPanel>
    <h2 class="headers">Right Thigh</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["RightUpLeg"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightUpLeg"]: {x: value, y: this.state["RightUpLeg"].y, z: this.state["RightUpLeg"].z}});
            window.changeRotation("RightUpLeg", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightUpLeg"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["RightUpLeg"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["RightUpLeg"]: {x: this.state["RightUpLeg"].x, y: value, z: this.state["RightUpLeg"].z}
            });
            window.changeRotation("RightUpLeg", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightUpLeg"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["RightUpLeg"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightUpLeg"]: {x: this.state["RightUpLeg"].x, y: this.state["RightUpLeg"].y, z: value}});
            window.changeRotation("RightUpLeg", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightUpLeg"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    <h2 class="headers">Right Calf</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-2.3}
            max={0}
            value={Number(this.state["RightLeg"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightLeg"]: {x: value, y: this.state["RightLeg"].y, z: this.state["RightLeg"].z}});
            window.changeRotation("RightLeg", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightLeg"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>

    <h2 class="headers">Right Foot</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["RightFoot"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightFoot"]: {x: value, y: this.state["RightFoot"].y, z: this.state["RightFoot"].z}});
            window.changeRotation("RightFoot", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightFoot"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["RightFoot"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["RightFoot"]: {x: this.state["RightFoot"].x, y: value, z: this.state["RightFoot"].z}
            });
            window.changeRotation("RightFoot", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightFoot"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["RightFoot"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightFoot"]: {x: this.state["RightFoot"].x, y: this.state["RightFoot"].y, z: value}});
            window.changeRotation("RightFoot", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightFoot"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
        </div>
<h2 class="headers">Right Toes</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["RightToeBase"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightToeBase"]: {x: value, y: this.state["RightToeBase"].y, z: this.state["RightToeBase"].z}});
            window.changeRotation("RightToeBase", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightToeBase"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["RightToeBase"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["RightToeBase"]: {x: this.state["RightToeBase"].x, y: value, z: this.state["RightToeBase"].z}
            });
            window.changeRotation("RightToeBase", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightToeBase"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["RightToeBase"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["RightToeBase"]: {x: this.state["RightToeBase"].x, y: this.state["RightToeBase"].y, z: value}});
            window.changeRotation("RightToeBase", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["RightToeBase"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
</div>
</TabPanel>
<TabPanel>
    <h2 class="headers">Upper Back</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["SpineHigh"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["SpineHigh"]: {x: value, y: this.state["SpineHigh"].y, z: this.state["SpineHigh"].z}});
            window.changeRotation("SpineHigh", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineHigh"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["SpineHigh"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["SpineHigh"]: {x: this.state["SpineHigh"].x, y: value, z: this.state["SpineHigh"].z}
            });
            window.changeRotation("SpineHigh", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineHigh"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["SpineHigh"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["SpineHigh"]: {x: this.state["SpineHigh"].x, y: this.state["SpineHigh"].y, z: value}});
            window.changeRotation("SpineHigh", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineHigh"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    <h2 class="headers">Mid Back</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["SpineMid"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["SpineMid"]: {x: value, y: this.state["SpineMid"].y, z: this.state["SpineMid"].z}});
            window.changeRotation("SpineMid", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineMid"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["SpineMid"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["SpineMid"]: {x: this.state["SpineMid"].x, y: value, z: this.state["SpineMid"].z}
            });
            window.changeRotation("SpineMid", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineMid"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["SpineMid"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["SpineMid"]: {x: this.state["SpineMid"].x, y: this.state["SpineMid"].y, z: value}});
            window.changeRotation("SpineMid", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineMid"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    <h2 class="headers">Lower Back</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-3}
            max={3}
            value={Number(this.state["SpineLow"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["SpineLow"]: {x: value, y: this.state["SpineLow"].y, z: this.state["SpineLow"].z}});
            window.changeRotation("SpineLow", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineLow"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-3}
            max={3}
            value={Number(this.state["SpineLow"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["SpineLow"]: {x: this.state["SpineLow"].x, y: value, z: this.state["SpineLow"].z}
            });
            window.changeRotation("SpineLow", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineLow"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-3}
            max={3}
            value={Number(this.state["SpineLow"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["SpineLow"]: {x: this.state["SpineLow"].x, y: this.state["SpineLow"].y, z: value}});
            window.changeRotation("SpineLow", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["SpineLow"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    <h2 class="headers">Root</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-3}
            max={3}
            value={Number(this.state["Root_Main"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["Root_Main"]: {x: value, y: this.state["Root_Main"].y, z: this.state["Root_Main"].z}});
            window.changeRotation("Root_Main", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["RootMain"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-6}
            max={6}
            value={Number(this.state["Root_Main"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["Root_Main"]: {x: this.state["Root_Main"].x, y: value, z: this.state["Root_Main"].z}
            });
            window.changeRotation("Root_Main", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["RootMain"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-3}
            max={3}
            value={Number(this.state["Root_Main"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["Root_Main"]: {x: this.state["Root_Main"].x, y: this.state["Root_Main"].y, z: value}});
            window.changeRotation("Root_Main", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["RootMain"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
</TabPanel>
<TabPanel>
<h2 class="headers">Left Thigh</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["LeftUpLeg"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftUpLeg"]: {x: value, y: this.state["LeftUpLeg"].y, z: this.state["LeftUpLeg"].z}});
            window.changeRotation("LeftUpLeg", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftUpLeg"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["LeftUpLeg"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["LeftUpLeg"]: {x: this.state["LeftUpLeg"].x, y: value, z: this.state["LeftUpLeg"].z}
            });
            window.changeRotation("LeftUpLeg", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftUpLeg"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["LeftUpLeg"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftUpLeg"]: {x: this.state["LeftUpLeg"].x, y: this.state["LeftUpLeg"].y, z: value}});
            window.changeRotation("LeftUpLeg", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftUpLeg"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
    </div>
    <h2 class="headers">Left Calf</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-2.3}
            max={0}
            value={Number(this.state["LeftLeg"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftLeg"]: {x: value, y: this.state["LeftLeg"].y, z: this.state["LeftLeg"].z}});
            window.changeRotation("LeftLeg", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftLeg"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>

    <h2 class="headers">Left Foot</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["LeftFoot"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftFoot"]: {x: value, y: this.state["LeftFoot"].y, z: this.state["LeftFoot"].z}});
            window.changeRotation("LeftFoot", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftFoot"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["LeftFoot"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["LeftFoot"]: {x: this.state["LeftFoot"].x, y: value, z: this.state["LeftFoot"].z}
            });
            window.changeRotation("LeftFoot", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftFoot"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["LeftFoot"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftFoot"]: {x: this.state["LeftFoot"].x, y: this.state["LeftFoot"].y, z: value}});
            window.changeRotation("LeftFoot", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftFoot"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
        </div>
<h2 class="headers">Left Toes</h2>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["LeftToeBase"].x).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftToeBase"]: {x: value, y: this.state["LeftToeBase"].y, z: this.state["LeftToeBase"].z}});
            window.changeRotation("LeftToeBase", value, "x");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftToeBase"];
            for (let child in children) {
                window.changeRotation(child, value, "x");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.001}
            min={-1}
            max={1.5}
            value={Number(this.state["LeftToeBase"].y).toFixed(2)}
            onChange={(event, value) => {
            this.setState({
                ["LeftToeBase"]: {x: this.state["LeftToeBase"].x, y: value, z: this.state["LeftToeBase"].z}
            });
            window.changeRotation("LeftToeBase", value, "y");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftToeBase"];
            for (let child in children) {
                window.changeRotation(child, value, "y");
            }
        }}/>
    </div>
    <div className="control">
        <Slider
            valueLabelDisplay="auto"
            step={.01}
            min={-1}
            max={1}
            value={Number(this.state["LeftToeBase"].z).toFixed(2)}
            onChange={(event, value) => {
            this.setState({["LeftToeBase"]: {x: this.state["LeftToeBase"].x, y: this.state["LeftToeBase"].y, z: value}});
            window.changeRotation("LeftToeBase", value, "z");
            // Apply the rotation to the child bones
            const children = this.state.trans["LeftToeBase"];
            for (let child in children) {
                window.changeRotation(child, value, "z");
            }
        }}/>
</div>
</TabPanel>
<TabPanel>
<h2 class="headers">Thumb Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandThumbHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandThumbHigh"]: {x: value, y: this.state["LeftHandThumbHigh"].y, z: this.state["LeftHandThumbHigh"].z}});
        window.changeRotation("LeftHandThumbHigh", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandThumbHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Thumb Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandThumbMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandThumbMid"]: {x: value, y: this.state["LeftHandThumbMid"].y, z: this.state["LeftHandThumbMid"].z}});
        window.changeRotation("LeftHandThumbMid", value, "x");
        const children = this.state.trans["LeftHandThumbMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Thumb Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandThumbLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandThumbLow"]: {x: value, y: this.state["LeftHandThumbLow"].y, z: this.state["LeftHandThumbLow"].z}});
        window.changeRotation("LeftHandThumbLow", value, "x");
        const children = this.state.trans["LeftHandThumbLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandThumbLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandThumbLow"]: {x: this.state["LeftHandThumbLow"].x, y: this.state["LeftHandThumbLow"].y, z: value}});
        window.changeRotation("LeftHandThumbLow", value, "z");
        const children = this.state.trans["LeftHandThumbLow"];
        for (let child in children) {
            window.changeRotation(child, value, "z");
        }
    }}/>
</div>
<h2 class="headers">Index Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandIndexHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandIndexHigh"]: {x: value, y: this.state["LeftHandIndexHigh"].y, z: this.state["LeftHandIndexHigh"].z}});
        window.changeRotation("LeftHandIndexHigh", value, "x");
        const children = this.state.trans["LeftHandIndexHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Index Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandIndexMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandIndexMid"]: {x: value, y: this.state["LeftHandIndexMid"].y, z: this.state["LeftHandIndexMid"].z}});
        window.changeRotation("LeftHandIndexMid", value, "x");
        const children = this.state.trans["LeftHandIndexMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Index Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandIndexLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandIndexLow"]: {x: value, y: this.state["LeftHandIndexLow"].y, z: this.state["LeftHandIndexLow"].z}});
        window.changeRotation("LeftHandIndexLow", value, "x");
        const children = this.state.trans["LeftHandIndexLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.001}
        min={-1}
        max={1.5}
        value={Number(this.state["LeftHandIndexLow"].y).toFixed(2)}
        onChange={(event, value) => {
        this.setState({
            ["LeftHandIndexLow"]: {x: this.state["LeftHandIndexLow"].x, y: value, z: this.state["LeftHandIndexLow"].z}
        });
        window.changeRotation("LeftHandIndexLow", value, "y");
        const children = this.state.trans["LeftHandIndexLow"];
        for (let child in children) {
            window.changeRotation(child, value, "y");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandIndexLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandIndexLow"]: {x: this.state["LeftHandIndexLow"].x, y: this.state["LeftHandIndexLow"].y, z: value}});
        window.changeRotation("LeftHandIndexLow", value, "z");
        const children = this.state.trans["LeftHandIndexLow"];
        for (let child in children) {
            window.changeRotation(child, value, "z");
        }
    }}/>
</div>
<h2 class="headers">Middle Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandMiddleHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandMiddleHigh"]: {x: value, y: this.state["LeftHandMiddleHigh"].y, z: this.state["LeftHandMiddleHigh"].z}});
        window.changeRotation("LeftHandMiddleHigh", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandMiddleHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Middle Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandMiddleMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandMiddleMid"]: {x: value, y: this.state["LeftHandMiddleMid"].y, z: this.state["LeftHandMiddleMid"].z}});
        window.changeRotation("LeftHandMiddleMid", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandMiddleMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Middle Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandMiddleLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandMiddleLow"]: {x: value, y: this.state["LeftHandMiddleLow"].y, z: this.state["LeftHandMiddleLow"].z}});
        window.changeRotation("LeftHandMiddleLow", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandMiddleLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.001}
        min={-1}
        max={1.5}
        value={Number(this.state["LeftHandMiddleLow"].y).toFixed(2)}
        onChange={(event, value) => {
        this.setState({
            ["LeftHandMiddleLow"]: {x: this.state["LeftHandMiddleLow"].x, y: value, z: this.state["LeftHandMiddleLow"].z}
        });
        window.changeRotation("LeftHandMiddleLow", value, "y");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandMiddleLow"];
        for (let child in children) {
            window.changeRotation(child, value, "y");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandMiddleLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandMiddleLow"]: {x: this.state["LeftHandMiddleLow"].x, y: this.state["LeftHandMiddleLow"].y, z: value}});
        window.changeRotation("LeftHandMiddleLow", value, "z");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandMiddleLow"];
        for (let child in children) {
            window.changeRotation(child, value, "z");
        }
    }}/>
</div>
<h2 class="headers">Ring Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandRingHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandRingHigh"]: {x: value, y: this.state["LeftHandRingHigh"].y, z: this.state["LeftHandRingHigh"].z}});
        window.changeRotation("LeftHandRingHigh", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandRingHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Ring Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandRingMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandRingMid"]: {x: value, y: this.state["LeftHandRingMid"].y, z: this.state["LeftHandRingMid"].z}});
        window.changeRotation("LeftHandRingMid", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandRingMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Ring Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandRingLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandRingLow"]: {x: value, y: this.state["LeftHandRingLow"].y, z: this.state["LeftHandRingLow"].z}});
        window.changeRotation("LeftHandRingLow", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandRingLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.001}
        min={-1}
        max={1.5}
        value={Number(this.state["LeftHandRingLow"].y).toFixed(2)}
        onChange={(event, value) => {
        this.setState({
            ["LeftHandRingLow"]: {x: this.state["LeftHandRingLow"].x, y: value, z: this.state["LeftHandRingLow"].z}
        });
        window.changeRotation("LeftHandRingLow", value, "y");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandRingLow"];
        for (let child in children) {
            window.changeRotation(child, value, "y");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandRingLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandRingLow"]: {x: this.state["LeftHandRingLow"].x, y: this.state["LeftHandRingLow"].y, z: value}});
        window.changeRotation("LeftHandRingLow", value, "z");
       // Apply the rotation to the child bones
       const children = this.state.trans["LeftHandRingLow"];
       for (let child in children) {
           window.changeRotation(child, value, "z");
       }
    }}/>
</div>
<h2 class="headers">Pinky Tip</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandPinkyHigh"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandPinkyHigh"]: {x: value, y: this.state["LeftHandPinkyHigh"].y, z: this.state["LeftHandPinkyHigh"].z}});
        window.changeRotation("LeftHandPinkyHigh", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandPinkyHigh"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Pinky Mid</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandPinkyMid"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandPinkyMid"]: {x: value, y: this.state["LeftHandPinkyMid"].y, z: this.state["LeftHandPinkyMid"].z}});
        window.changeRotation("LeftHandPinkyMid", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandPinkyMid"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<h2 class="headers">Pinky Low</h2>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandPinkyLow"].x).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandPinkyLow"]: {x: value, y: this.state["LeftHandPinkyLow"].y, z: this.state["LeftHandPinkyLow"].z}});
        window.changeRotation("LeftHandPinkyLow", value, "x");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandPinkyLow"];
        for (let child in children) {
            window.changeRotation(child, value, "x");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.001}
        min={-1}
        max={1.5}
        value={Number(this.state["LeftHandPinkyLow"].y).toFixed(2)}
        onChange={(event, value) => {
        this.setState({
            ["LeftHandPinkyLow"]: {x: this.state["LeftHandPinkyLow"].x, y: value, z: this.state["LeftHandPinkyLow"].z}
        });
        window.changeRotation("LeftHandPinkyLow", value, "y");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandPinkyLow"];
        for (let child in children) {
            window.changeRotation(child, value, "y");
        }
    }}/>
</div>
<div className="control">
    <Slider
        valueLabelDisplay="auto"
        step={.01}
        min={-1}
        max={1}
        value={Number(this.state["LeftHandPinkyLow"].z).toFixed(2)}
        onChange={(event, value) => {
        this.setState({["LeftHandPinkyLow"]: {x: this.state["LeftHandPinkyLow"].x, y: this.state["LeftHandPinkyLow"].y, z: value}});
        window.changeRotation("LeftHandPinkyLow", value, "z");
        // Apply the rotation to the child bones
        const children = this.state.trans["LeftHandPinkyLow"];
        for (let child in children) {
            window.changeRotation(child, value, "z");
        }
    }}/>
</div>
</TabPanel>
</Tabs>
<hr></hr>
    <div className="export" onClick={this.exportPose}>Export Pose</div>
    </div>
    );
}
}

export default Editor;
import React from 'react';
import './noUser.css';

function NoUser({ isOpen, onClose, onConfirm, children }) {

  return (
    <div className="modalBackdrop">
      <div className="modalContent">
        <h1 className='modalTitle textError'>ACCESS DENIED</h1>
        <p className='modalText'>To acquire items you must first exist.</p>
        <p className='modalText'> Please log in using the Profile section.</p>
        <div className="buttonContainer">
          <button className='oh' onClick={onClose}>Oh...</button>
        </div>
      </div>
    </div>
  );
}

export default NoUser;
import React, { useState } from "react";
import "../css/Poser.css";

function ToggleSwitch({ onToggle }) {
  const [isToggled, setIsToggled] = useState(false);

  const toggleSwitch = () => {
    setIsToggled(!isToggled);
    onToggle(!isToggled);
  };
  

  return (
    <label className="poserContainer">
      <input type="checkbox" checked={isToggled} onChange={toggleSwitch} />
      <span className="pslider round"></span>
    </label>
  );
}

function PoseToggle({ onToggle = () => {} }) {
  const [isHoldingPoseButton, setIsHoldingPoseButton] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [halfSecondTimerId, setHalfSecondTimerId] = useState(null);
  const [buttonContent, setButtonContent] = useState("Bones");

  const handlePoseButtonClick = () => {
    if (!isHoldingPoseButton) {
      setIsHoldingPoseButton(true);
      const newTimerId = setTimeout(() => {
        setIsHoldingPoseButton(false);
        setTimerId(null);
        activatePoseButton();
        setButtonContent("🤓");
        setTimeout(() => {
          setButtonContent("Bones");
        }, 3000);
      }, 3000);
      setTimerId(newTimerId);

      const newHalfSecondTimerId = setTimeout(() => {
        setHalfSecondTimerId(null);
      }, 500);
      setHalfSecondTimerId(newHalfSecondTimerId);
    }
  };

  const handlePoseButtonRelease = () => {
    if (isHoldingPoseButton) {
      setIsHoldingPoseButton(false);

      if (timerId) {
        clearTimeout(timerId);
        setTimerId(null);
      }

      if (halfSecondTimerId) {
        clearTimeout(halfSecondTimerId);
        setHalfSecondTimerId(null);
      } else {
        setButtonContent("🤨");
        setTimeout(() => {
          setButtonContent("Bones");
        }, 3000);
      }
    }
  };
  

  const activatePoseButton = () => {
    const exportPoseEvent = new CustomEvent('exportPose');
    window.dispatchEvent(exportPoseEvent);
  };

  return (
    <div className="pose-toggle">
      <div
        className="hiddenbuttons"
        id="Button"
        onMouseDown={handlePoseButtonClick}
        onMouseUp={handlePoseButtonRelease}
        onMouseLeave={handlePoseButtonRelease}
      >
        {buttonContent}
      </div>
      <div className="toggle-switch">
        <ToggleSwitch onToggle={onToggle}
         />
      </div>
    </div>
  );
}

export default PoseToggle;

import React, { useState, useEffect, useContext } from 'react';
import './itemCard.css';
import Confirmation from '../modal/ConfirmPurchase/Confirmation';
import NoUser from '../modal/noUser/NoUser';
import { AppContext } from '../../pages/AppContext';

function ItemCard({ saleItem, updateCredits }) {
  const { inventory, setInventory, userUid } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [isItemOwned, setIsItemOwned] = useState(false);

  useEffect(() => {
    const checkItemOwnership = async () => {
      if (userUid && saleItem) {
        try {
          const url = `https://czbackend.herokuapp.com/api/CZMarket/checkItemInInventory?userId=${userUid}&itemId=${saleItem.item_id}&itemType=${saleItem.category}`;
          const response = await fetch(url);
          const result = await response.json();
          setIsItemOwned(result.isOwned);
        } catch (error) {
          console.error('Failed to check item ownership:', error);
        }
      } else {
        setIsItemOwned(false);
      }
    };

    checkItemOwnership();
  }, [userUid, saleItem]);



  console.log('UID:', userUid);
  console.log('Is Item Owned:', isItemOwned);
  console.log(saleItem.item_id);
  if (!saleItem) return null;

  const handleConfirm = async (itemId) => {
    if (userUid) {
      try {
        const response = await fetch('https://czbackend.herokuapp.com/api/CZMarket/addItemToInventory', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userUid, 
            itemId: itemId,
            itemType: saleItem.category, 
          }),
        });

        const result = await response.json();
        if (result.success) {
          setInventory([...inventory, saleItem]);
          updateCredits(result.credits);
          alert('Item added successfully!');
        } else {
          alert(result.message); 
        }
      } catch (error) {
        console.error('Failed to add item to inventory:', error);
        alert('Error adding item to inventory.');
      }
    }
    closeModal();
  };

  const handleAddToInventory = () => {
    setInventory([...inventory, saleItem]);
    closeModal();
  };

  const handleRemoveFromInventory = () => {
    setInventory(inventory.filter(invItem => invItem.item_id !== saleItem.item_id));
    closeModal();
  };

  const openModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getModalContent = () => {
    if (userUid === null) {
      console.log('NULL DUDE');
      return <NoUser onClose={closeModal} />;
    } else {
      return (
        <Confirmation onClose={closeModal} onConfirm={() => handleConfirm(saleItem.item_id)} itemId={saleItem.item_id} saleItem={saleItem}>
        </Confirmation>
      );
    }
  };

  return (
<div className="col">
  <div className="itemContainer">
    <a className={`InventoryAction ${isItemOwned ? 'active' : ''}`} href="#"
      onClick={(e) => {
        if (isItemOwned) {
          e.preventDefault();
          console.log('Item is already "owned", buddy.')
          return; 
        }
        e.preventDefault(); 
        openModal(getModalContent());
      }}>
      <div className="itemCard">
        <div className="itemFeature">{saleItem.category}</div>
        <div className="imageWrapper">
          <img src={`https://citizenzero.s3.us-west-1.amazonaws.com/thumbs/${saleItem.category}/${saleItem.item_name}.png`} alt={saleItem.item_name} className="img-fluid" />
        </div>
        <div className="itemDetails">
          <h4 className="itemTitle">{saleItem.item_name}</h4>
        </div>
        <span className={`itemPrice ${isItemOwned ? 'itemPriceOwned' : ''} ${saleItem.discount_amount > 0 ? 'itemPriceDiscounted' : ''}`}>
            {isItemOwned ? "ACQUIRED" : (saleItem.price - saleItem.discount_amount).toFixed(0)}
          </span>
      </div>
    </a>
  </div>
  {isModalOpen && modalContent}
</div>


  );
  
}

export default ItemCard;

import React from 'react';
import './sideMenu/sideMenu.css';

function NavListItem({ item, navOnClick }) {
  // Apply 'factoryButton' class if the item's name is 'Factory'
  const linkClasses = `${item.name === 'Factory' ? 'factoryButton' : ''} ${item._id === 6 ? 'specialItem' : ''} ${item.active ? 'active' : ''}`;

  return (
    <li>
      <a
        href="#"
        className={linkClasses.trim()}
        onClick={(e) => {
          e.preventDefault(); // Prevent default link behavior
          navOnClick(item._id, item.target);
        }}
      >
        <span className="navIcon">{item.icon}</span>
        <span className="navName">{item.name}</span>
      </a>
    </li>
  );
}

export default NavListItem;
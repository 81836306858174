// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAnzPoz441QM4Gh1s7k7UjQbD4VzrUOK9A",
    authDomain: "citizen-zero-backend.firebaseapp.com",
    projectId: "citizen-zero-backend",
    storageBucket: "citizen-zero-backend.appspot.com",
    messagingSenderId: "348705296477",
    appId: "1:348705296477:web:0a35138d4d0fac4c873db4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };

// Shared Imports
import React, { Component } from 'react';

// Marketplace App
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Main from './pages/main/Main';

// Factory App
import { CustomView, isMobileOnly, isTablet, isBrowser, withOrientationChange } from "react-device-detect";
// import logo from "./assets/Logo_Light.png";
// import * as React from 'react';
import logo from './assets/logo/CZSVGLOGO.svg';
import Logo from './assets/Logo_Light.png';
import './css/App.css';
import './css/universal/universal.css';
import CategoryList from "./library/category.json";
import MainStage from "./components/webgl/MainStage";
import FloatingSvg from './assets/bgicons/x.svg'; // Adjust the path as necessary
// Factory Loaded Components
import PageLoader from "./components/PageLoader";
import Name from "./components/Name";
import Download from "./components/Download";

import Category from "./components/category/Category";
import PartLoader from "./components/PartLoader";
import Screenshot from "./components/Screenshot";
import Welcome from './components/modal/welcome/Welcome';
import Editor from './Backups/Editor';
import Selector from './components/Selector';
import MobilePage from "./components/mobilePage/MobilePage";


export const AppContext = React.createContext();

class App extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      // Marketplace App
      library: [],
      bag: [],
      cred: '0',
      total: '10000',
      // Factory App
      category: CategoryList,
      currentCategory: "pose",
      avatarName: "Cosmo",
      UIDisplayed: true,
      welcome: true,
      message: "MESSAGE",
      loadedMeshes: {
        Body: "LPCOSMO",
        Chest: "LPC_Chest",
        LegR: "no_leg_R",
        LegL: "no_leg_L",
        PadR: "no_pad_R",
        PadL: "no_pad_L",
        Head: "no_hat",
        ArmR: "no_arm_R",
        ArmL: "no_arm_L",
        HandR: "no_hand_R",
        HandL: "no_hand_L",
        FootR: "no_foot_R",
        FootL: "no_foot_L",
        Stand: "circle2",
        Env: "meshEnv",
        Podium: "none",
        Prop01: "none",
        Prop02: "none",
        Prop03: "none"
      },
      screenshot: true,
      editor: false,
      partLoading: false,
      poseToggle: false,
      animate: false,
      userId: null, 
    }
    this.mainStage = null;
  };

  // Factory App Reset Loaded Meshes
  resetLoadedMeshes = () => {
    this.setState({
      loadedMeshes: {
        Head: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        PadR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        PadL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        Chest: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        LegR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        LegL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        ArmR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        ArmL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        HandR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        HandL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        FootR: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        },
        FootL: {
          name: undefined,
          rotation: { x: 0, y: 0, z: 0 }
        }
      },
    });
  };

  
  // Factory App Component Did Mount/Updates
  componentDidMount() {
    this.setBackgroundImage();

    if (isBrowser || isTablet) {
      //this.mainStage = new MainStage();
      //this.mainStage.init();
      //this.mainStage.animate();

    } else {
      //this.mainStage = new MainStage();
      //this.mainStage.init();
      //this.mainStage.animate();
    }
  }

  setBackgroundImage = () => {
    const backgrounds = [
      "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Inferno+Beach/nz.jpg",
      "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/lighthouse/pz.jpg",
      "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Forgotten+Grove/nx.jpg",
      "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Simulation+P-9/px.jpg"

    ];
    const randomIndex = Math.floor(Math.random() * backgrounds.length);
    const selectedBackground = backgrounds[randomIndex];
    document.body.style.backgroundImage = `url('${selectedBackground}')`;
  };

  componentDidUpdate() {
    //if (this.mainStage) {
    //  this.mainStage.updatePoseToggle(this.state.poseToggle);
    //}
  }

  handlePoseToggleChange = (isToggled) => {
    this.setState({ poseToggle: isToggled });
    // Any other logic you need to execute when the toggle changes
  };

  // Update the state of parent App from child Component
  updateCategory = currentCategory => {
    this.setState({ currentCategory });
  };
  updateAvatarName = avatarName => {
    this.setState({ avatarName });
  };
  updateWelcome = welcome => {
    this.setState({ welcome });
  };
  updatePopup = popup => {
    this.setState({ popup });
  };
  updateMeshes = loadedMeshes => {
    this.setState({ loadedMeshes });
  };
  updateLoading = partLoading => {
    this.setState({ partLoading });
  };
  updatePopupMessage = message => {
    this.setState({ message });
  };
  updateScreenshot = screenshot => {
    this.setState({ screenshot });
  };
  handleAnimate = (animate) => {
    this.setState({ animate });
  }
  
//drill creds
updateCredits = (newCredits) => {
  this.setState({ cred: newCredits });
};


  // Marketplace App "sets"
  // Method to update state for library
  setLibrary = (library) => {
    this.setState({ library });
  }

  // Method to update state for bag
  setBag = (bag) => {
    this.setState({ bag });
  }

  // Method to update state for cred
  setCred = (cred) => {
    this.setState({ cred });
  }

  // Method to update state for total
  setTotal = (total) => {
    this.setState({ total });
  }


  render() {
    return (
      <div className="App">
         
        <CustomView condition={isBrowser || isTablet}>
          <AppContext.Provider value={{
            ...this.state,
            setLibrary: this.setLibrary,
            setBag: this.setBag,
            setCred: this.setCred,
            setTotal: this.setTotal,
            animate: this.animate,
            updateCategory: this.updateCategory,
            userId: this.userId,
          }}>
            <Main 
            updateCredits={this.updateCredits}
            popup={this.state.popup}
            message={this.state.message}
            updatePopup={this.updatePopup}
            welcome={this.state.welcome}
            updateWelcome={this.updateWelcome}
            category={this.state.category}
            currentCategory={this.state.currentCategory}
            updateCategory={this.updateCategory}
            updatePose={this.updatePose}
            UIDisplayed={this.state.UIDisplayed}
            loadedMeshes={this.state.loadedMeshes}
            updateMeshes={this.updateMeshes}
            updatePopupMessage={this.updatePopupMessage}
            editor={this.state.editor}
            updateLoading={this.updateLoading}
            avatarName={this.state.avatarName}
            updateAvatarName={this.updateAvatarName}
            onPoseToggleChange={this.handlePoseToggleChange}
            resetLoadedMeshes={this.resetLoadedMeshes}
            animate={this.animate}
            handlePoseToggleChange={this.handlePoseToggleChange}
            poseToggle={this.state.poseToggle}
            />


            </AppContext.Provider>
        </CustomView>
        <CustomView condition={isMobileOnly}>
          <MobilePage/>
        </CustomView>
      </div>
    );
  };
}
App = withOrientationChange(App)

export default App;

import React, { useState, useEffect, useContext } from 'react';
import './home.css';
import ItemCard from '../../components/itemCard/ItemCard';
import CoinCard from '../../components/coinCard/CoinCard';
import { AppContext } from '../AppContext'; // 
import { Timeline } from 'react-twitter-widgets';
import Showcase from '../../components/showcase/Showcase';
import games from '../../api/gamesData.json';

function Home({ coins, reference, updateCredits }) {
  const [items, setItems] = useState([]);
  const [inventory, setInventory] = useState([]);
  const { userId } = useContext(AppContext);
  const [colClass, setColClass] = useState('col-12');

  //this needs to pull all items not items for sale. this is a functional test. make new backend API
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch('https://czbackend.herokuapp.com/api/CZMarket/items-for-sale');
        if (!response.ok) throw new Error('Failed to fetch items.');
        const data = await response.json();
        setItems(data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };
    fetchItems();
    const handleResize = () => setColClass(getColClass(window.innerWidth));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getColClass = (width) => {
    if (width >= 1200) {
      return 'col-lg-3';
    } else if (width >= 992) {
      return 'col-md-4';
    } else if (width >= 768) {
      return 'col-md-6';
    } else {
      return 'col-12';
    }
  };

  useEffect(() => {
    setColClass(getColClass(window.innerWidth));
  }, []);

  return (
    <section id="home" className="home active" ref={reference}>
      <div className="container-fluid">
        <div className="row" style={{ height: 'auto' }}>  {/* Use a style to ensure the row has a dynamic height based on content */}
          <div className="col-lg-6 invisascroll" style={{ display: 'flex', Height: '335px', paddingBottom: '10px' }}>
            <div className="myShadow" style={{ width: '100%', height: '100%', maxHeight: '300px', backgroundColor: 'var(--primary)', borderRadius: '10px', padding: '5px' }}> {/* Container for Timeline to fill */}
            <Showcase games={games} />
            </div>
          </div>

          {/* Existing Coin area, now taking up 75% of the row */}
          <div className="col-lg-6 " style={{ paddingBottom: '10px' }}>
            <div className='credArea invisascroll' style={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '300px' }}>
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="sectionTitle">GET CRED</h2>
                </div>
              </div>
              <div className="row cards" style={{ flex: 1 }}> {/* Make sure cards area expands to fill available space */}
                {coins
                  .filter(coin => coin.active === true)
                  .slice(0, 8)
                  .map(coin => (
                    <CoinCard key={coin._id} coin={coin} />
                  ))}
              </div>
            </div>
          </div>
          
        </div>

        <div className="row">
          <div className="col-lg-12">
            <h2 className="sectionTitle">Item Specials</h2>
            <p className='componentsubtext'>Save 50 cred on these items</p>
          </div>
        </div>
        <div className="row cards">
          <div className="col">
            <div className="row">
              {items
                .filter(saleItem => saleItem.is_discounted && parseInt(saleItem.discount_amount) > 0)
                .slice(0, 8)
                .map(saleItem => (
                  <div key={saleItem.item_id} className={`${colClass} extendo`}>
                    <ItemCard saleItem={saleItem} inventory={inventory.some(invItem => invItem.item_id === saleItem.item_id)} updateCredits={updateCredits} />
                  </div>
                ))}
            </div>
          </div>
        </div>

      </div>
    </section>

  );
}


export default Home;

import React, { useContext } from 'react';
import './coinCard.css';
import { AppContext } from '../../App';
import BgSvg from '../../assets/bgicons/coinBg.svg';
function CoinCard({ coin }) {


  return (
    <div className="col">
      <div className="coinContainer">
        <div className="coinCard">
          <span className="coinDescription">
            {coin.description}
          </span>
          <span className="coinTitle">
            {coin.title} Cred
          </span>
          <img src={coin.img} className="coinImg" />
          <span className="coinPrice">
          ${coin.price.toFixed(2)}
        </span>
        </div>
        
      </div>

    </div>
  );
}

export default CoinCard;
// Updated react 18 import
// import ReactDOM from "react-dom/client";

import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
//import { Provider } from 'react-redux';
//import store from './store/store';
import { AppProvider } from './pages/AppContext';
//import reportWebVitals from './reportWebVitals';

// Below is the React 18 update. This results in the body mesh being loaded twice. unknown as to why.
//const root = ReactDOM.createRoot(document.getElementById("root"));
//root.render(
  //<React.StrictMode>
    //<App />
  //</React.StrictMode>
//);
//<Provider store={store}>

ReactDOM.render(
  <React.StrictMode>
      <AppProvider> {/* Make sure AppProvider wraps around App */}
        <App />
      </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);

import React, { useState, useEffect, useContext } from 'react';
import './profile.css';
import { auth, provider } from '../../auth/firebase-config'; // Adjust the path as necessary
import { signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
// Import Firebase utilities if using Firebase for authentication
// import { auth } from './firebase-config'; // Adjust the path according to your setup
// import { onAuthStateChanged } from 'firebase/auth';
import { AppContext } from '../AppContext';

function Profile({ reference, updateCredits }) {
  const { userUid, setUserUid, userData, setUserData, userImg, setUserImg, setUserName, credits, setCredits } = useContext(AppContext);
  const [email, setEmail] = useState('');

  //this maintains persistence for actions such as refreshes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserUid(user.uid);
        const idToken = await user.getIdToken();
        try {
          // Verify the user's token and fetch basic user data
          const verifyTokenResponse = await fetch('https://czbackend.herokuapp.com/api/CZMarket/verify-token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: idToken }),
          });

          if (!verifyTokenResponse.ok) throw new Error('Failed to verify token');

          const basicUserData = await verifyTokenResponse.json();

          // Update state with basic user data
          setUserData(basicUserData);
          setCredits(basicUserData.credits);
          setUserImg(basicUserData.photoURL);
          setUserName(basicUserData.username);


          // Fetch detailed item information
          const itemsResponse = await fetch(`https://czbackend.herokuapp.com/api/CZMarket/listItemsInInventory?userId=${user.uid}`, {
            method: 'GET', // Assuming GET method for this endpoint
            headers: {
              // Include any necessary headers, such as Authorization headers, if your endpoint requires them
            }
          });

          if (!itemsResponse.ok) throw new Error('Failed to fetch detailed items');

          const itemsData = await itemsResponse.json();

          // Update the userData state to include the detailed items
          // Assuming you want to add this as a new property to your existing userData object
          setUserData((prevUserData) => ({
            ...prevUserData,
            detailedItems: itemsData, // Add the fetched items data to the user's data
          }));

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        // Reset user-related state if not logged in
        setUserData(null);
        setUserUid(null);
        setUserImg('https://citizenzero.s3.us-west-1.amazonaws.com/icons/UI/defaultUser.png');
        setUserName('Not Logged In');
      }
    });

    return () => unsubscribe();
  }, [setUserUid, setUserData, auth]);


  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
    } else {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUserData(null);
        setUserUid(null);
        setEmail('');
        setCredits('0');
        console.log('Sign out successful');
      }).catch((error) => {

        console.error('Error signing out:', error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://czbackend.herokuapp.com/api/CZMarket/update-user-details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error('Failed to update user data');
      }

      const updatedUserData = await response.json();
      const newlyFetchedCredits = updatedUserData.credits;
      setCredits(newlyFetchedCredits);
      setUserData(updatedUserData);
      setEmail(updatedUserData.email);
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating user data:', error);
      alert('Failed to update profile.');
    }
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const idToken = await result.user.getIdToken();
        try {
          const response = await fetch('https://czbackend.herokuapp.com/api/CZMarket/verify-token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: idToken }),
          });
          if (!response.ok) throw new Error('Failed to fetch user data');
          const fetchedUserData = await response.json();
          const newlyFetchedCredits = fetchedUserData.credits;
          console.log('CREDITS', newlyFetchedCredits);
          setUserData(fetchedUserData);
          setCredits(newlyFetchedCredits);
          setUserImg(fetchedUserData.photoURL);
          setUserName(fetchedUserData.username);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }).catch((error) => {
        console.error(error.message);
      });
  };


  if (!userData) {
    return (
      <section id="profile" className="profile" ref={reference}>
        <div className='loginContainer'>
          <h1 className='centering'>Create an account to exist.</h1>
          <p className='centering'>Account deletion is not possible yet. This site is still under heavy development.</p>
          <p className='centering'>Issues, comments, suggestions? Please email me at dude_its_Matt_G@proton.me</p>
          <button class="gsi-material-button" onClick={signInWithGoogle}>
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: "block" }}>
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span className="gsi-material-button-contents">Continue with Google</span>
              <span style={{ display: "none" }}>Continue with Google</span>
            </div>
          </button>
        </div>
      </section>
    );
  }

  return (

    <section id="profile" className="profile" ref={reference}>
      <form onSubmit={handleSubmit}>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-with-buttons">
                <h2>Profile Information</h2>
                <div className='action-buttons'>
                  <button className='login' type="submit">Save Changes</button>
                  <button className='signOut' type="button" onClick={handleSignOut}>Sign Out</button>
                </div>
              </div>
              <div className="profile-grid">
                <div className="user-info">
                  <div className='profsep'>
                    <div>
                      <label htmlFor="username">Username: </label>
                      <input id="username" name="username" value={userData.username} onChange={handleChange} />
                    </div>
                    <div>
                      <strong>Email:</strong> <span>{userData.email}</span>
                    </div>
                  </div>
                  <div className='profsep'>
                    <div>
                      <label htmlFor="firstName">First Name:</label>
                      <input id="firstName" name="first_name" value={userData.first_name} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="middleInitial">Middle Initial:</label>
                      <input id="middleInitial" name="middle_initial" value={userData.middle_initial} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="lastName">Last Name:</label>
                      <input id="lastName" name="last_name" value={userData.last_name} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="streetAddress">Street Address:</label>
                      <input id="streetAddress" name="street_address" value={userData.street_address} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="city">City:</label>
                      <input id="city" name="city" value={userData.city} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="state">State:</label>
                      <input id="state" name="state" value={userData.state} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="zipCode">Zip code:</label>
                      <input id="zipCode" name="zip_code" value={userData.zip_code} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="country">Country:</label>
                      <input id="country" name="country" value={userData.country} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="phoneNumber">Phone Number:</label>
                      <input id="phoneNumber" name="phone_number" value={userData.phone_number} onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className="right-side-info">
                  <div className='profsep credbal'>
                    <p className='credit-title'><strong>Cred Balance</strong></p>

                    <p> <span className="credit-balance">{userData ? userData.credits : 'Loading...'}</span></p>
                  </div>
                  <div className="profsep">
                    <div>
                      <p><strong>Purchased Items:</strong></p>
                    </div>
                    <div className="table__body main table">
                      {userData.detailedItems && userData.detailedItems.length > 0 ? (
                        <table>
                          <tbody>
                            {userData.detailedItems.map((item, index) => (
                              <tr key={index}>
                                <td className='tableImgComp'>
                                  <img
                                    src={`https://citizenzero.s3.us-west-1.amazonaws.com/thumbs/${item.item_type}/${item.item_name}.png`}
                                    alt={item.item_name}
                                    className="img-fluid" // Ensures the image is responsive
                                  />
                                </td>
                                <td className='tableTextComp'>{item.item_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>Inventory is empty.</p>
                      )}
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}

export default Profile;

import React, { useState, useEffect, useContext } from 'react';
import './confirmation.css';
import { AppContext } from '../../../pages/AppContext';


function Confirmation({ saleItem, onConfirm, onClose, isOpen, children, itemId }) {
  const { credits} = useContext(AppContext);
  const titles = [
    "Initiating acquisition sequence.",
    "Attention, User!",
    "Prepare for upgrade!",
    "System alert:",
    "Confirm transaction?",
    "Activation required!",
    "Acquisition protocol engaged.",
    "Unit ready for acquisition.",
    "Enhance capabilities?"
  ];

  const errorTitles = [
    "Credit Transfer: Unsuccessful",
    "Access Denied:",
    "Awaiting Credit Fulfillment...",
    "User Action Required: Add Credits",
    "Acquisition Protocol Interrupted",
  ];

  // State to hold the randomly chosen title
  const [modalTitle, setModalTitle] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  // Effect to set a random title when the component mounts
  useEffect(() => {
    setModalTitle(titles[Math.floor(Math.random() * titles.length)]);
    setErrorTitle(errorTitles[Math.floor(Math.random() * errorTitles.length)]);
  }, []); // Empty dependency array ensures this runs only once at mount



  const netCost = (saleItem.price - saleItem.discount_amount).toFixed(0);

  return (
    <div className="modalBackdrop">
      <div className="modalContent">
      {netCost <= credits ? (
          <>
        <h1 className='modalTitle confAdjust'>{modalTitle}</h1>
        <img className="confThumb" src={`https://citizenzero.s3.us-west-1.amazonaws.com/thumbs/${saleItem.category}/${saleItem.item_name}.png`} alt={saleItem.item_name} />
            <p>You are about to acquire:</p>
            <p className='confTitle' style={{ fontSize: 'xx-large', color: 'var(--secondary)' }}>{saleItem.item_name}</p>
            <p>You will spend <span style={{ color: 'var(--secondary)' }}>{netCost}</span> Cred.</p>
            <p style={{ textDecoration: 'underline', paddingTop: '10px' }}>REFUNDS ARE NOT AUTHORIZED.</p>
            <hr />
            <div className="buttonContainer">
              <button className='yes' onClick={onConfirm}>Continue</button>
              <button className='no' onClick={onClose}>Cancel</button>
            </div>
          </>
        ) : (
          <>
          <h1 className='modalTitle confAdjust textError'>{errorTitle}</h1>
        <img className="confThumb errorImg" src={`https://citizenzero.s3.us-west-1.amazonaws.com/thumbs/${saleItem.category}/${saleItem.item_name}.png`} alt={saleItem.item_name} />
            <p>Not enough credits.</p>
            <hr />
            <div className="buttonContainer">
              <button className='no centerNo' onClick={onClose}>Darn.</button>
            </div>
          </>
        )}
      </div>
    </div>
  );


}

export default Confirmation;